import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";

function AboutPartial() {
  return (
    <section className="flex justify-center bg-gray-black">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#7AF3E1"
            fill-opacity="1"
            d="M0,128L48,117.3C96,107,192,85,288,69.3C384,53,480,43,576,48C672,53,768,75,864,80C960,85,1056,75,1152,85.3C1248,96,1344,128,1392,144L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
        <div className="flex">
          <div className="md:px-24 sm:px-6 mb-16">
            <div className="flex justify-center">
              <div className="flex flex-col">
                <div
                  className="lg:text-6xl sm:text-4xl font-bold text-white"
                  data-aos="fade-up"
                >
                  About
                </div>
                <div className="text-white pt-8" data-aos="fade-up">
                  Nice to meetchya!
                </div>
                <div className="text-white my-16" data-aos="fade-up">
                  Hey there, I'm Thomas, your friendly neighborhood
                  entrepreneur, full-stack programmer, AI enthusiast, and early
                  adopter all rolled into one. I've been on the cutting edge of
                  technology, having launched one of the first GPT-3 chatbots
                  well over a year before ChatGPT hit the scene. Along the way,
                  I've had the privilege of consulting at Google and embarking
                  on numerous AI projects, crafting innovative products, and
                  putting together captivating demos. This is my digital
                  playground, where I love to share my latest ideas, creations,
                  and passion projects. When I'm not diving into the world of
                  code and AI, I'm busy making music that resonates with the
                  soul and creating art that tells a story. If you're in need of
                  some serious tech wizardry, a musical journey, or an artistic
                  touch, don't hesitate to reach out – let's bring your visions
                  to life together! Stay clever, stay creative, and stay cool!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutPartial;
