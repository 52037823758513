import React, { useState, useEffect, useRef } from "react";
import {
  chat,
  get_agent,
  new_chat,
  upload_file,
  get_chat,
  delete_chat,
  delete_data,
  new_agent,
  regenerate,
  edit,
} from "../merlinv1/api";
import ReactMardown from "react-markdown";

const ChatPartial = React.forwardRef((props, ref) => {
  let searchParams = new URLSearchParams(window.location.pathname);
  var currentMessageId = 0;

  const [agent, setAgent] = useState({});

  const [file, setFile] = useState("");
  const fileInputRef = useRef();

  const [agentName, setAgentName] = useState("New Agent");
  const [agentId, setAgentId] = useState("");
  const [chatId, setChatId] = useState("");
  const [renderKey, setRenderKeyForMessages] = useState(0);
  const [lastAIID, setLastAIID] = useState(0);

  const [editStates, setEditStates] = useState([]);
  const [userMessage, setUserMessage] = useState("");

  const [dataList, setDataList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const [flattenedMessages, setFlattenedMessages] = useState([
    [1, "string", "FakeMessage", 0, 111, []],
  ]);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  function adjustHeight(textarea) {
    textarea.style.height = "auto";
    if (textarea.scrollHeight <= 200) {
      textarea.style.height = textarea.scrollHeight + "px";
    } else {
      textarea.style.height = "200px";
    }
  }

  function Message({
    message,
    updatePreviousIndex,
    nextIndex,
    previousUpdatePreviousIndex,
    forwardIndex,
    isEditing,
    setIsEditing,
    editingIndex,
    setLocalMessage,
    localMessage,
    setCopied,
    copied,
  }) {
    if (message != undefined && message.length > 1) {
      const [
        currentNestedLength,
        content,
        type,
        feedback,
        messageId,
        nextMessages,
      ] = message;

      const isMoveLeftEnabled = forwardIndex > 0;
      const isMoveRightEnabled = forwardIndex < currentNestedLength - 1;

      const handleMoveLeft = () => {
        if (isMoveLeftEnabled) {
          previousUpdatePreviousIndex(forwardIndex - 1);
        }
      };

      const handleMoveRight = () => {
        if (isMoveRightEnabled) {
          previousUpdatePreviousIndex(forwardIndex + 1);
        }
      };

      async function copyTextToClipboard(text) {
        if ("clipboard" in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand("copy", true, text);
        }
      }

      const handleCopyClick = (text) => {
        copyTextToClipboard(text).then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1500);
        });
      };

      return (
        <div>
          {Array.isArray(nextMessages) && (
            <div>
              <div>
                {content && (
                  <div className="message-content">
                    {type === "HumanMessage" ? (
                      <div
                        className="w-full text-gray-white"
                        data-testid="conversation-turn-2"
                      >
                        <div className="group px-4 py-2 justify-center text-base md:gap-6 m-auto">
                          <div className="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] }">
                            <div className="flex-shrink-0 flex flex-col relative items-end">
                              <div>
                                <div className="pt-0.5">
                                  <div className="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                                    <div className="relative flex">👤</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="relative flex w-full flex-col lg:w-[calc(100%-115px)]">
                              <div className="font-semibold select-none">
                                You
                              </div>
                              <div className="flex-col gap-1 md:gap-3">
                                {isEditing[editingIndex] ? (
                                  <div className="flex flex-grow flex-col max-w-full">
                                    <textarea
                                      className="m-0 resize-none border-0 bg-transparent p-0 focus-none"
                                      value={localMessage}
                                      onChange={(e) => {
                                        adjustHeight(e.target);
                                        setLocalMessage(e.target.value);
                                      }}
                                    ></textarea>
                                    <div className="text-center mt-2 flex justify-center">
                                      <button
                                        className="btn relative bg-gray-black hover:border-gray-white mr-2"
                                        as="button"
                                        onClick={() => {
                                          handleEdit(messageId, localMessage);
                                        }}
                                      >
                                        <div className="flex w-full gap-2 items-center justify-center text-white">
                                          Save &amp; Submit
                                        </div>
                                      </button>
                                      <button
                                        className="btn relative bg-gray-black hover:border-gray-white"
                                        as="button"
                                        onClick={() => {
                                          var tempIsEditing = [...isEditing];
                                          tempIsEditing[editingIndex] = false;
                                          setIsEditing(tempIsEditing);
                                        }}
                                      >
                                        <div className="flex w-full gap-2 items-center justify-center text-white">
                                          Cancel
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="flex flex-grow flex-col max-w-full">
                                      <div
                                        data-message-author-role="user"
                                        data-message-id="aaa2b2ea-4c83-4331-9548-43f33f713205"
                                        className="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto"
                                      >
                                        <div className="">{content}</div>
                                      </div>
                                    </div>
                                    <div className="mt-1 flex justify-start gap-3 empty:hidden">
                                      {currentNestedLength !== 1 && (
                                        <div class="text-xs flex items-center justify-center gap-1 self-center visible">
                                          <div
                                            class="dark:text-white disabled:text-gray-300 dark:disabled:text-gray-400"
                                            onClick={() => {
                                              handleMoveLeft();
                                            }}
                                          >
                                            <svg
                                              stroke="currentColor"
                                              fill="none"
                                              stroke-width="2"
                                              viewBox="0 0 24 24"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="icon-xs"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <polyline points="15 18 9 12 15 6"></polyline>
                                            </svg>
                                          </div>
                                          <span class="flex-grow flex-shrink-0 tabular-nums">
                                            {forwardIndex + 1} /{" "}
                                            {currentNestedLength}
                                          </span>

                                          <div
                                            disabled=""
                                            class="dark:text-white disabled:text-gray-300 dark:disabled:text-gray-400"
                                            onClick={() => {
                                              handleMoveRight();
                                            }}
                                          >
                                            <svg
                                              stroke="currentColor"
                                              fill="none"
                                              stroke-width="2"
                                              viewBox="0 0 24 24"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="icon-xs"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <polyline points="9 18 15 12 9 6"></polyline>
                                            </svg>
                                          </div>
                                        </div>
                                      )}
                                      <div className="text-gray-400 flex self-end lg:self-center justify-center lg:justify-start mt-0 gap-1 visible">
                                        <button
                                          className="p-1 pl-0 rounded-md disabled:dark:hover:text-gray-400 dark:hover:text-gray-200 dark:text-gray-400 text-gray-400 hover:text-gray-950 invisible group-hover:visible"
                                          onClick={() => {
                                            var tempIsEditing = [...isEditing];
                                            tempIsEditing[editingIndex] = true;
                                            setIsEditing(tempIsEditing);
                                            setLocalMessage(content);
                                          }}
                                        >
                                          <div className="flex items-center gap-1.5 text-xs">
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="icon-md"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M13.2929 4.29291C15.0641 2.52167 17.9359 2.52167 19.7071 4.2929C21.4783 6.06414 21.4783 8.93588 19.7071 10.7071L18.7073 11.7069L11.1603 19.2539C10.7182 19.696 10.1489 19.989 9.53219 20.0918L4.1644 20.9864C3.84584 21.0395 3.52125 20.9355 3.29289 20.7071C3.06453 20.4788 2.96051 20.1542 3.0136 19.8356L3.90824 14.4678C4.01103 13.8511 4.30396 13.2818 4.7461 12.8397L13.2929 4.29291ZM13 7.41422L6.16031 14.2539C6.01293 14.4013 5.91529 14.591 5.88102 14.7966L5.21655 18.7835L9.20339 18.119C9.40898 18.0847 9.59872 17.9871 9.7461 17.8397L16.5858 11L13 7.41422ZM18 9.5858L14.4142 6.00001L14.7071 5.70712C15.6973 4.71693 17.3027 4.71693 18.2929 5.70712C19.2831 6.69731 19.2831 8.30272 18.2929 9.29291L18 9.5858Z"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : type === "AIMessage" ? (
                      <div className="group w-full text-gray-white">
                        <div className="px-4 py-2 justify-center text-base md:gap-6 m-auto">
                          <div className="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] }">
                            <div className="flex-shrink-0 flex flex-col relative items-end">
                              <div>
                                <div className="pt-0.5">
                                  <div className="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                                    <div className="relative p-1 rounded-sm h-9 w-9 text-white flex items-center justify-center">
                                      🤖
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="relative flex w-full flex-col lg:w-[calc(100%-115px)] agent-turn">
                              <div className="font-semibold select-none">
                                Spooky
                              </div>
                              <div className="flex-col gap-1 md:gap-3">
                                <div className="flex flex-grow flex-col max-w-full">
                                  <div className="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto">
                                    <div className="markdown prose w-full break-words text-white">
                                      <span>
                                        <ReactMardown>{content}</ReactMardown>
                                        {loading && messageId === lastAIID && (
                                          <svg
                                            viewBox="8 4 8 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="cursor"
                                          >
                                            <rect
                                              x="10"
                                              y="6"
                                              width="4"
                                              height="12"
                                              fill="#fff"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="mt-1 flex justify-start gap-3">
                                  {currentNestedLength !== 1 && (
                                    <div class="text-xs flex items-center justify-center gap-1 self-center visible">
                                      <div
                                        class="dark:text-white disabled:text-gray-300 dark:disabled:text-gray-400"
                                        onClick={() => {
                                          handleMoveLeft();
                                        }}
                                      >
                                        <svg
                                          stroke="currentColor"
                                          fill="none"
                                          stroke-width="2"
                                          viewBox="0 0 24 24"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="icon-xs"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <polyline points="15 18 9 12 15 6"></polyline>
                                        </svg>
                                      </div>
                                      <span class="flex-grow flex-shrink-0 tabular-nums">
                                        {forwardIndex + 1} /{" "}
                                        {currentNestedLength}
                                      </span>

                                      <div
                                        disabled=""
                                        class="dark:text-white disabled:text-gray-300 dark:disabled:text-gray-400"
                                        onClick={() => {
                                          handleMoveRight();
                                        }}
                                      >
                                        <svg
                                          stroke="currentColor"
                                          fill="none"
                                          stroke-width="2"
                                          viewBox="0 0 24 24"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="icon-xs"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <polyline points="9 18 15 12 9 6"></polyline>
                                        </svg>
                                      </div>
                                    </div>
                                  )}
                                  <div className="text-gray-400 flex self-end lg:self-center justify-center lg:justify-start mt-0 gap-1 visible">
                                    <button
                                      className="flex items-center gap-1.5 rounded-md p-1 pl-0 text-xs hover:text-gray-950 dark:text-gray-400 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 invisible group-hover:visible"
                                      onClick={() => handleCopyClick(content)}
                                    >
                                      {!copied ? (
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="icon-md"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M12 4C10.8954 4 10 4.89543 10 6H14C14 4.89543 13.1046 4 12 4ZM8.53513 4C9.22675 2.8044 10.5194 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V7C4 5.34315 5.34315 4 7 4H8.53513ZM8 6H7C6.44772 6 6 6.44772 6 7V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V7C18 6.44772 17.5523 6 17 6H16C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      ) : (
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="icon-md"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M18.0633 5.67375C18.5196 5.98487 18.6374 6.607 18.3262 7.06331L10.8262 18.0633C10.6585 18.3093 10.3898 18.4678 10.0934 18.4956C9.79688 18.5234 9.50345 18.4176 9.29289 18.2071L4.79289 13.7071C4.40237 13.3166 4.40237 12.6834 4.79289 12.2929C5.18342 11.9023 5.81658 11.9023 6.20711 12.2929L9.85368 15.9394L16.6738 5.93664C16.9849 5.48033 17.607 5.36263 18.0633 5.67375Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      )}
                                    </button>

                                    <div className="flex gap-1">
                                      <button className="p-1 pl-0 rounded-md disabled:dark:hover:text-gray-400 dark:hover:text-gray-200 dark:text-gray-400 text-gray-400 hover:text-gray-950 invisible group-hover:visible">
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="icon-md"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M12.1318 2.50389C12.3321 2.15338 12.7235 1.95768 13.124 2.00775L13.5778 2.06447C16.0449 2.37286 17.636 4.83353 16.9048 7.20993L16.354 8.99999H17.0722C19.7097 8.99999 21.6253 11.5079 20.9313 14.0525L19.5677 19.0525C19.0931 20.7927 17.5124 22 15.7086 22H6C4.34315 22 3 20.6568 3 19V12C3 10.3431 4.34315 8.99999 6 8.99999H8C8.25952 8.99999 8.49914 8.86094 8.6279 8.63561L12.1318 2.50389ZM10 20H15.7086C16.6105 20 17.4008 19.3964 17.6381 18.5262L19.0018 13.5262C19.3488 12.2539 18.391 11 17.0722 11H15C14.6827 11 14.3841 10.8494 14.1956 10.5941C14.0071 10.3388 13.9509 10.0092 14.0442 9.70591L14.9932 6.62175C15.3384 5.49984 14.6484 4.34036 13.5319 4.08468L10.3644 9.62789C10.0522 10.1742 9.56691 10.5859 9 10.8098V19C9 19.5523 9.44772 20 10 20ZM7 11V19C7 19.3506 7.06015 19.6872 7.17071 20H6C5.44772 20 5 19.5523 5 19V12C5 11.4477 5.44772 11 6 11H7Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </button>
                                      <button className="p-1 pl-0 rounded-md disabled:dark:hover:text-gray-400 dark:hover:text-gray-200 dark:text-gray-400 text-gray-400 hover:text-gray-950 invisible group-hover:visible">
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="icon-md"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M11.8727 21.4961C11.6725 21.8466 11.2811 22.0423 10.8805 21.9922L10.4267 21.9355C7.95958 21.6271 6.36855 19.1665 7.09975 16.7901L7.65054 15H6.93226C4.29476 15 2.37923 12.4921 3.0732 9.94753L4.43684 4.94753C4.91145 3.20728 6.49209 2 8.29589 2H18.0045C19.6614 2 21.0045 3.34315 21.0045 5V12C21.0045 13.6569 19.6614 15 18.0045 15H16.0045C15.745 15 15.5054 15.1391 15.3766 15.3644L11.8727 21.4961ZM14.0045 4H8.29589C7.39399 4 6.60367 4.60364 6.36637 5.47376L5.00273 10.4738C4.65574 11.746 5.61351 13 6.93226 13H9.00451C9.32185 13 9.62036 13.1506 9.8089 13.4059C9.99743 13.6612 10.0536 13.9908 9.96028 14.2941L9.01131 17.3782C8.6661 18.5002 9.35608 19.6596 10.4726 19.9153L13.6401 14.3721C13.9523 13.8258 14.4376 13.4141 15.0045 13.1902V5C15.0045 4.44772 14.5568 4 14.0045 4ZM17.0045 13V5C17.0045 4.64937 16.9444 4.31278 16.8338 4H18.0045C18.5568 4 19.0045 4.44772 19.0045 5V12C19.0045 12.5523 18.5568 13 18.0045 13H17.0045Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </button>
                                      {true && (
                                        <button
                                          className="p-1 pl-0 rounded-md disabled:dark:hover:text-gray-400 dark:hover:text-gray-200 dark:text-gray-400 text-gray-400 hover:text-gray-950 invisible group-hover:visible"
                                          onClick={() => {
                                            handleRegenerate(messageId);
                                          }}
                                        >
                                          <div className="flex items-center gap-1.5 text-xs">
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="icon-md"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M4.5 2.5C5.05228 2.5 5.5 2.94772 5.5 3.5V5.07196C7.19872 3.47759 9.48483 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C7.1307 21.5 3.11828 17.8375 2.565 13.1164C2.50071 12.5679 2.89327 12.0711 3.4418 12.0068C3.99033 11.9425 4.48712 12.3351 4.5514 12.8836C4.98798 16.6089 8.15708 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C9.7796 4.5 7.7836 5.46469 6.40954 7H9C9.55228 7 10 7.44772 10 8C10 8.55228 9.55228 9 9 9H4.5C3.96064 9 3.52101 8.57299 3.50073 8.03859C3.49983 8.01771 3.49958 7.99677 3.5 7.9758V3.5C3.5 2.94772 3.94771 2.5 4.5 2.5Z"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
              <RecursiveListRenderer
                key={messageId}
                messageList={[nextMessages[nextIndex]]}
                previousUpdatePreviousIndex={updatePreviousIndex}
                forwardIndex={nextIndex}
              />
            </div>
          )}
        </div>
      );
    }
  }

  function RecursiveListRenderer({
    messageList,
    previousUpdatePreviousIndex,
    forwardIndex,
  }) {
    // State to keep track of the current index
    const [currentIndexLocal, setCurrentIndexLocal] = useState(0);
    const [isEditing, setIsEditing] = useState(
      Array(messageList.length).fill(false)
    );
    const [localMessage, setLocalMessage] = useState();
    const [copied, setCopied] = useState(false);

    // Function to update the current index
    const updatePreviousIndexLocal = (newIndex) => {
      setCurrentIndexLocal(newIndex);
    };

    if (!Array.isArray(messageList) || messageList.length === 0) {
      return null;
    }

    // get last rendered index of messageList
    if (messageList.length > 0) {
      messageList.map((message, index) => {
        if (message != undefined && message.length > 0) {
          if (message[4] != undefined) {
            currentMessageId = message[4];
          }
        }
      });
    }

    return messageList.map((message, index) => (
      <Message
        key={index}
        message={message}
        updatePreviousIndex={updatePreviousIndexLocal}
        nextIndex={currentIndexLocal}
        previousUpdatePreviousIndex={previousUpdatePreviousIndex}
        forwardIndex={forwardIndex}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        editingIndex={index}
        setLocalMessage={setLocalMessage}
        localMessage={localMessage}
        setCopied={setCopied}
        copied={copied}
      />
    ));
  }

  function appendMessage(
    messageId,
    message,
    flattenedMessages,
    date,
    message_type
  ) {
    // Use a function that deeply clones the array with structural changes
    const recursiveSearch = (arr) => {
      return arr.map((msg) => {
        if (msg[4] === messageId) {
          const newMsg = [...msg]; // clone the message array
          newMsg[5] = [[1, message, message_type, 0, date, []]];
          return newMsg;
        } else if (Array.isArray(msg[5])) {
          return [...msg.slice(0, 5), recursiveSearch(msg[5])]; // clone and recurse
        } else {
          return msg; // return unmodified message
        }
      });
    };
    return recursiveSearch(flattenedMessages);
  }

  function updateMessage(messageId, message, flattenedMessages) {
    const recursiveSearch = (arr) => {
      return arr.map((msg) => {
        if (msg[4] === messageId) {
          const newMsg = [...msg]; // clone the message array
          newMsg[1] = message; // update the message
          return newMsg;
        } else if (Array.isArray(msg[5])) {
          return [...msg.slice(0, 5), recursiveSearch(msg[5])]; // clone and recurse
        } else {
          return msg; // return unmodified message
        }
      });
    };

    return recursiveSearch(flattenedMessages);
  }

  const chatAgent = async (message) => {
    try {
      setLoading(true);

      var tempFlattenedMessages = [...flattenedMessages];

      // append human message
      var humanDatetime = new Date();
      var newHumanMessageId = humanDatetime.getTime();

      if (tempFlattenedMessages.length !== 0) {
        tempFlattenedMessages = appendMessage(
          currentMessageId,
          message,
          tempFlattenedMessages,
          newHumanMessageId,
          "HumanMessage"
        );
      } else {
        tempFlattenedMessages = [
          [1, message, "HumanMessage", 0, newHumanMessageId, []],
        ];
      }

      var AIDatetime = new Date();
      var newAIMessageId = AIDatetime.getTime() + 1;

      // append empty AI message
      tempFlattenedMessages = appendMessage(
        newHumanMessageId,
        "...",
        tempFlattenedMessages,
        newAIMessageId,
        "AIMessage"
      );
      setRenderKeyForMessages(renderKey + 1);
      setFlattenedMessages(tempFlattenedMessages); // This will trigger a re-render
      setLastAIID(newAIMessageId);

      var element = document.getElementById("scrollableDiv");
      element.scrollTop = element.scrollHeight;

      // Start the chat and get the stream reader
      const res_reader = await chat(newHumanMessageId, tempFlattenedMessages);

      if (!res_reader || typeof res_reader.read !== "function") {
        console.error("Invalid stream reader:", res_reader);
        // Handle the error appropriately
        // For example, show an error message to the user or attempt a fallback method
      }

      console.log("reader type " + typeof res_reader);

      // Initialize the text decoder
      const decoder = new TextDecoder();
      let fullOutput = "";
      console.log("AI Message " + newAIMessageId);
      console.log("Human Message " + newHumanMessageId);
      // Process each chunk of data

      res_reader
        .read()
        .then(function processChatMessageTextMessageText({ done, value }) {
          if (done) {
            setLoading(false);
            console.log("Stream complete");
            return;
          }

          // Decode the chunk and update the message
          const chunk = decoder.decode(value, { stream: true });
          fullOutput += chunk;

          tempFlattenedMessages = updateMessage(
            newAIMessageId,
            fullOutput,
            tempFlattenedMessages
          );
          setRenderKeyForMessages(renderKey + 1);
          setFlattenedMessages(tempFlattenedMessages); // This will trigger a re-render

          console.log(fullOutput);
          // Read the next chunk
          res_reader.read().then(processChatMessageTextMessageText);

          var element = document.getElementById("scrollableDiv");
          element.scrollTop = element.scrollHeight;
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  function appendNextMessagesToParent(
    messageId,
    message,
    flattenedMessages,
    date,
    message_type
  ) {
    // Use a function that deeply clones the array with structural changes
    const recursiveSearch = (arr) => {
      return arr.map((msg) => {
        if (msg[4] === messageId) {
          const updatedMsg = [...msg];
          if (!updatedMsg[5]) {
            updatedMsg[5] = [];
          }
          updatedMsg[5].unshift([1, message, message_type, 0, date, []]);
          // update all the first element of the next_messages array to the length of the array
          for (var i = 0; i < updatedMsg[5].length; i++) {
            updatedMsg[5][i][0] = updatedMsg[5].length;
          }
          return updatedMsg;
        } else if (Array.isArray(msg[5])) {
          return [...msg.slice(0, 5), recursiveSearch(msg[5])]; // clone and recurse
        } else {
          return msg; // return unmodified message
        }
      });
    };
    return recursiveSearch(flattenedMessages);
  }

  function findParentId(nestedArray, targetId) {
    // Define a recursive function to search for the target ID
    function search(array, parentId) {
      // Iterate over each element in the array
      for (let i = 0; i < array.length; i++) {
        const element = array[i];

        // Check if the element is an array
        if (Array.isArray(element)) {
          // If the element is an array, check its fifth element (ID)
          if (element.length > 4 && element[4] === targetId) {
            // If the ID matches the target ID, return the parent ID
            return parentId;
          }

          // Recursively search in the nested array
          const result = search(element, array[4]);
          if (result !== null) {
            return result;
          }
        }
      }

      // Return null if the target ID is not found
      return null;
    }

    // Start the search with the top-level array
    return search(nestedArray, null);
  }

  const handleRegenerate = async (regenerateMessageId) => {
    try {
      setLoading(true);
      console.log("current id " + regenerateMessageId);
      var tempFlattenedMessages = [...flattenedMessages];

      // new message id
      var newDatetime = new Date();
      var newMessageId = newDatetime.getTime();

      // find the parent id of the regenerateMessageId
      console.log(regenerateMessageId);
      var parentMessageId = findParentId(
        tempFlattenedMessages,
        regenerateMessageId
      );

      console.log("parent id " + parentMessageId);

      // append new message to the parent next_messages of regenerateMessageId using a new function
      tempFlattenedMessages = appendNextMessagesToParent(
        parentMessageId,
        "...",
        tempFlattenedMessages,
        newMessageId,
        "AIMessage"
      );

      const reader = await chat(newMessageId, tempFlattenedMessages);

      console.log(tempFlattenedMessages);
      setRenderKeyForMessages(renderKey + 1);
      setFlattenedMessages(tempFlattenedMessages); // This will trigger a re-render
      setLastAIID(newMessageId);

      var element = document.getElementById("scrollableDiv");
      element.scrollTop = element.scrollHeight;

      // Initialize the text decoder
      const decoder = new TextDecoder();
      let fullOutput = "";
      // Process each chunk of data
      reader
        .read()
        .then(function processChatMessageTextMessageText({ done, value }) {
          if (done) {
            setLoading(false);
            console.log("Stream complete");
            return;
          }

          // Decode the chunk and update the message
          const chunk = decoder.decode(value, { stream: true });
          fullOutput += chunk;

          tempFlattenedMessages = updateMessage(
            newMessageId,
            fullOutput,
            tempFlattenedMessages
          );

          console.log(tempFlattenedMessages);

          setRenderKeyForMessages(renderKey + 1);
          setFlattenedMessages(tempFlattenedMessages); // This will trigger a re-render
          // Read the next chunk
          reader.read().then(processChatMessageTextMessageText);

          var element = document.getElementById("scrollableDiv");
          element.scrollTop = element.scrollHeight;
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (messageId, newMessage) => {
    try {
      setLoading(true);
      var tempFlattenedMessages = [...flattenedMessages];

      // new message id
      var humanDatetime = new Date();
      var newHumanMessageId = humanDatetime.getTime();

      // find the parent id of the messageId
      var parentMessageId = findParentId(tempFlattenedMessages, messageId);

      console.log("message id " + messageId);
      console.log("parent message id " + parentMessageId);

      if (parentMessageId === null) {
        // WRITE CODE TO HANDLE NO PARENT ID
        // append edited human message to the top level of the array
        tempFlattenedMessages.push([
          1,
          newMessage,
          "HumanMessage",
          0,
          newHumanMessageId,
          [],
        ]);
      } else {
        // append new message to the parent next_messages of regenerateMessageId using a new function
        tempFlattenedMessages = appendNextMessagesToParent(
          parentMessageId,
          newMessage,
          tempFlattenedMessages,
          newHumanMessageId,
          "HumanMessage"
        );
      }

      // new message id
      var AIDatetime = new Date();
      var newAIMessageId = AIDatetime.getTime() + 1;

      tempFlattenedMessages = appendNextMessagesToParent(
        newHumanMessageId,
        "...",
        tempFlattenedMessages,
        newAIMessageId,
        "AIMessage"
      );

      // Start the chat and get the stream reader
      const reader = await chat(newHumanMessageId, tempFlattenedMessages);

      console.log(tempFlattenedMessages);
      setRenderKeyForMessages(renderKey + 1);
      setFlattenedMessages(tempFlattenedMessages); // This will trigger a re-render
      setLastAIID(newAIMessageId);

      var element = document.getElementById("scrollableDiv");
      element.scrollTop = element.scrollHeight;

      // Initialize the text decoder
      const decoder = new TextDecoder();
      let fullOutput = "";
      console.log(newAIMessageId);
      console.log(newHumanMessageId);
      // Process each chunk of data
      reader
        .read()
        .then(function processChatMessageTextMessageText({ done, value }) {
          if (done) {
            setLoading(false);
            console.log("Stream complete");
            return;
          }

          // Decode the chunk and update the message
          const chunk = decoder.decode(value, { stream: true });
          fullOutput += chunk;

          tempFlattenedMessages = updateMessage(
            newAIMessageId,
            fullOutput,
            tempFlattenedMessages
          );
          setRenderKeyForMessages(renderKey + 1);
          setFlattenedMessages(tempFlattenedMessages); // This will trigger a re-render
          // Read the next chunk
          reader.read().then(processChatMessageTextMessageText);

          var element = document.getElementById("scrollableDiv");
          element.scrollTop = element.scrollHeight;
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewChat = async () => {
    try {
      setFlattenedMessages([[1, "string", "FakeMessage", 0, 111, []]]);
      forceUpdate();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteChat = async (index) => {
    try {
      var res = await delete_chat(agentId, index, agent);
      console.log(res.data);
      var res = await get_chat(agentId, res.data.chats.length - 1, agent);
      setAgent(res.data.agent);
      forceUpdate();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteData = async (index) => {
    try {
      var res = await delete_data(agentId, index, agent);
      console.log(res.data);
      setDataList(res.data.docs);
      setAgent(res.data.agent);
      forceUpdate();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectChat = async (index) => {
    var res = await get_chat(agentId, index, agent);
    console.log(res.data);
    setAgent(res.data.agent);
    forceUpdate();
  };

  const handleChange = async (event) => {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
    var file = event.target.files[0];
    var Base64;
    event.preventDefault();
    // Convert file to base64 encoding
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      Base64 = reader.result.split(",")[1];
      console.log(Base64);
      console.log(file.name);
      const res = await upload_file(Base64, file.name, agentId, agent);
      console.log(res.data);
      forceUpdate();
    };
  };

  useEffect(() => {
    // Force an update or perform actions when flattenedMessages changes
  }, [flattenedMessages]);

  useEffect(() => {
    // fetchData();
  }, []);

  return (
    <div className="relative z-0 flex h-full w-full overflow-hidden">
      <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
        {/* button going back to /demo */}

        <main className="relative h-full w-full flex-1 overflow-auto transition-width">
          <div
            role="presentation"
            className="flex h-full flex-col bg-gray-black"
          >
            <div className="flex-1 overflow-hidden">
              {flattenedMessages[0][5].length === 0 && (
                <div class="relative h-full">
                  <div class="absolute left-0 right-0">
                    <div class="h-1.5"></div>
                  </div>
                  <div class="flex h-full flex-col items-center justify-center">
                    <div class="relative">
                      <div class="mb-3 h-[72px] w-[72px]">
                        <div className="flex flex-col">
                          <span className="text-5xl">
                            <img src="https://storage.googleapis.com/quasi-a39a6.appspot.com/White%20Nabla.png"></img>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="mb-5 text-2xl font-medium text-white">
                      How can I help you?
                    </div>
                  </div>
                </div>
              )}
              <div className=" overflow-y-auto h-full" id="scrollableDiv">
                <div className="react-scroll-to-bottom--css-uutfu-1n7m0yu">
                  <div className="flex flex-col pb-9 text-sm">
                    <div className="sticky top-0 mb-2 flex items-center justify-between z-10 h-14 p-2 font-semibold bg-gray-bleck">
                      <div className="flex items-center gap-2">
                        <div
                          className="group flex cursor-pointer items-center gap-1 rounded-xl py-1 px-3 text-gray-white text-lg font-medium hover:bg-gray-medium"
                          type="button"
                          id="radix-:rf:"
                          aria-haspopup="menu"
                          aria-expanded="false"
                          data-state="closed"
                          onClick={() => {
                            handleNewChat();
                          }}
                        >
                          <div>
                            Spooky <span className="text-gray-white">1.1</span>
                          </div>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 16 16"
                            fill="none"
                            className="text-token-text-tertiary"
                          >
                            <path
                              d="M8 2 V14 M2 8 H14"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="flex gap-2 pr-1">
                        <button className="relative flex h-9 w-9 items-center justify-center whitespace-nowrap rounded-lg border border-gray-white focus:ring-0">
                          <div className="flex w-full gap-2 items-center justify-center">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon-md"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L13 5.41421V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V5.41421L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.90237 7.31658 6.90237 6.68342 7.29289 6.29289L11.2929 2.29289ZM4 13C4.55228 13 5 13.4477 5 14V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V14C3 13.4477 3.44772 13 4 13Z"
                                fill="white"
                              ></path>
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div key={renderKey}>
                      <RecursiveListRenderer
                        messageList={flattenedMessages}
                        forwardIndex={0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full pt-2 md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)]">
              <form className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
                <div className="relative flex h-full flex-1 items-stretch md:flex-col">
                  <div className="flex w-full items-center">
                    <div className="overflow-hidden [&amp;:has(textarea:focus)]:border-gray-white [&amp;:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)] flex flex-col w-full dark:border-gray-white flex-grow relative border border-token-gray-white text-white rounded-2xl bg-gray-medium dark:bg-gray-800 shadow-[0_0_0_2px_rgba(255,255,255,0.95)] dark:shadow-[0_0_0_2px_rgba(52,53,65,0.95)]">
                      <textarea
                        id="prompt-textarea"
                        tabIndex="0"
                        data-id="request-NEW:1-0"
                        rows="1"
                        placeholder="Send Message to Spooky..."
                        className="m-0 bg-gray-black w-full resize-none border-0 py-[10px] pr-10 focus:ring-0 focus-visible:ring-0 md:py-3.5 md:pr-12 pl-10 md:pl-[55px] text-white"
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                        value={userMessage}
                        onChange={(e) => {
                          // if e == \n dont setUserMessagex`
                          if (e.target.value === "\n") {
                            console.log("not setting");
                          } else {
                            setUserMessage(e.target.value);
                            adjustHeight(e.target);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.shiftKey) {
                            console.log("not submitting");
                            setUserMessage(userMessage);
                          } else if (
                            e.key === "Enter" &&
                            userMessage.trim() !== ""
                          ) {
                            chatAgent(userMessage);
                            setUserMessage("");
                            console.log(userMessage);
                          }
                        }}
                      ></textarea>
                      <div className="absolute left-0 mt-3 ml-3 hover:bg-gray-light rounded-xl">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <input
                          id="dropzone-file"
                          type="file"
                          class="hidden"
                          onChange={handleChange}
                          multiple={true}
                          ref={fileInputRef}
                        />
                      </div>

                      {!loading ? (
                        <div
                          className="absolute right-0 mt-3 mr-3 hover:bg-gray-light rounded-xl"
                          onClick={() => {
                            chatAgent(userMessage);
                          }}
                        >
                          <span className="">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              className="text-white"
                            >
                              <path
                                d="M7 11L12 6L17 11M12 18V7"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      ) : (
                        <div className="absolute right-0 mt-3 mr-3 hover:bg-gray-light rounded-xl">
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              class="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div className="relative px-2 py-2 text-center text-xs text-gray-white dark:text-gray-300 md:px-[60px]">
                <span>
                  Spooky can make mistakes. Consider checking important
                  information.
                </span>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
});

export default ChatPartial;
