import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Cookies } from "react-cookie";

export const firebaseConfig = {
  apiKey: "AIzaSyBmm7TJEbAIIlvL2II_5crBHsW6uN7QSvs",
  authDomain: "pondr-306720.firebaseapp.com",
  projectId: "pondr-306720",
  storageBucket: "pondr-306720.appspot.com",
  messagingSenderId: "900567060525",
  appId: "1:900567060525:web:5a931f5702e9b38390a8a4",
  measurementId: "G-EL1SMHGNFY",
};

const app = initializeApp(firebaseConfig);

export default app;
