import React, { useEffect, useState } from "react";

function NewHomePartial() {
  const [heroText, setHeroText] = useState([
    {
      content: " Welcome to GPTEZ!",
    },
  ]);

  const [displayResponse, setDisplayResponse] = useState("");
  const [completedTyping, setCompletedTyping] = useState(false);
  const [loremText, setLoremText] = useState(
    `🤖 ChatGPT
    As this person browses through the 'About' page of GPTEZ, a website created by AI programmer and entrepreneur Thomas Stahura, their thoughts might unfold like this:\n\nInitial Skepticism\n\n"Okay, here we go. Another AI site. Let's see how many buzzwords we can count today."\n"Demos, galleries, blogs... original. I bet he's got a neural network making coffee too."\n"Projects linked? How convenient, a self-promotion carousel. Let's click and see the 'revolutionary' work."\n\nObserving the Content\n\n"Hmm, this demo is actually pretty slick. Not bad, but I've seen smoother interfaces."\n"Wait, this gallery is quite extensive. He's been at this for a while, huh?"\n"His blog posts... okay, they're not just fluff. Some real insights here. Surprising depth."\n\nGradual Appreciation\n\n"I'll give him this, the guy knows his AI. These projects are more substantial than I expected."\n"There's a genuine passion here. Not just a businessman, but a real techie at heart."\n"The design is user-friendly too. Easy navigation, no annoying pop-ups. Refreshing."`
  );

  const [displayLorem, setDisplayLorem] = useState("");
  const [completedTypingLorem, setCompletedTypingLorem] = useState(false);
  const [isLoremVisible, setIsLoremVisible] = useState(false);
  const [pathData, setPathData] = useState(
    "M33.5,-61.3C39.1,-54.9,36.2,-37.1,35.7,-25C35.2,-12.8,37.1,-6.4,35.5,-0.9C33.8,4.5,28.7,9.1,24.1,12.3C19.5,15.6,15.4,17.5,11.4,19.9C7.5,22.2,3.7,24.9,-0.7,26.2C-5.2,27.5,-10.4,27.3,-15.2,25.5C-20,23.6,-24.3,20.1,-28.8,15.6C-33.2,11,-37.8,5.5,-42,-2.4C-46.2,-10.4,-50.1,-20.8,-50.9,-34.4C-51.7,-48.1,-49.5,-65.1,-40.6,-69.6C-31.6,-74.1,-15.8,-66.2,-0.9,-64.6C14,-63,28,-67.8,33.5,-61.3Z"
  );
  const [pathData2, setPathData2] = useState(
    "M39.4,-65.2C46.9,-63.9,45.9,-44.9,46.3,-31.1C46.6,-17.3,48.3,-8.7,51.3,1.7C54.2,12.1,58.6,24.2,52.8,28.6C47,33,31.1,29.6,20.6,38.3C10.1,47,5.1,67.7,-1.7,70.6C-8.4,73.4,-16.7,58.5,-29.1,50.9C-41.5,43.3,-57.9,43.1,-70.4,35.9C-82.9,28.6,-91.4,14.3,-84.3,4.1C-77.2,-6.1,-54.3,-12.1,-44.9,-24.6C-35.4,-37,-39.2,-55.8,-34,-58.3C-28.7,-60.9,-14.4,-47.2,0.8,-48.5C16,-49.9,31.9,-66.4,39.4,-65.2Z"
  );
  const [pathData3, setPathData3] = useState(
    "M23.5,-42.1C33,-35.2,44.9,-34,56.6,-28C68.2,-21.9,79.5,-10.9,83.5,2.3C87.6,15.6,84.4,31.2,75.4,41.9C66.4,52.5,51.6,58.2,38.1,55C24.6,51.8,12.3,39.7,0.9,38.1C-10.4,36.5,-20.8,45.3,-34.4,48.5C-47.9,51.7,-64.6,49.3,-65.6,40.3C-66.6,31.3,-51.8,15.7,-52.6,-0.5C-53.4,-16.6,-69.7,-33.1,-68,-40.8C-66.3,-48.5,-46.4,-47.3,-32.1,-51.3C-17.7,-55.3,-8.9,-64.6,-0.9,-63C7,-61.4,14,-48.9,23.5,-42.1Z"
  );
  const [pathData4, setPathData4] = useState(
    "M40.7,-66.6C50.9,-64.5,56,-49.9,57.4,-36.7C58.7,-23.6,56.2,-11.8,54,-1.2C51.9,9.3,50.1,18.6,47.8,30.2C45.6,41.8,42.9,55.6,34.8,57.3C26.8,59,13.4,48.5,3.5,42.3C-6.3,36.2,-12.6,34.4,-24.5,35C-36.5,35.6,-54.2,38.5,-60.3,33.1C-66.4,27.6,-61,13.8,-53.7,4.2C-46.5,-5.4,-37.3,-10.8,-32.1,-17.8C-26.8,-24.8,-25.5,-33.3,-20.8,-38.5C-16.1,-43.8,-8,-45.7,3.6,-52C15.2,-58.2,30.5,-68.7,40.7,-66.6Z"
  );
  const [intervalId, setIntervalId] = useState(null);
  const [intervalId2, setIntervalId2] = useState(null);
  const [intervalId3, setIntervalId3] = useState(null);
  const [intervalId4, setIntervalId4] = useState(null);

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  const checkVisibility = () => {
    const loremElement = document.getElementById("loremText");
    if (loremElement) {
      const rect = loremElement.getBoundingClientRect();
      const isLoremVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
      setIsLoremVisible(true);
    }
  };

  const generateRandomPath = () => {
    let pathArray = [
      "M32.1,-58.1C43.5,-49.1,55.9,-44.3,56.3,-35.3C56.6,-26.3,44.9,-13.2,43.3,-0.9C41.6,11.3,50.2,22.6,52.4,36C54.6,49.5,50.6,65.1,40.8,67C31,68.9,15.5,57.1,2.2,53.2C-11,49.3,-22.1,53.3,-31.3,51.1C-40.4,48.8,-47.7,40.3,-48.3,30.7C-48.9,21.2,-42.8,10.6,-46.2,-2C-49.7,-14.6,-62.8,-29.2,-59.7,-34.5C-56.7,-39.8,-37.5,-35.8,-24.9,-44.1C-12.3,-52.5,-6.1,-73,2.1,-76.7C10.4,-80.4,20.7,-67.1,32.1,-58.1Z",
      "M25.6,-37.3C36,-38.4,49.2,-37.2,60.1,-30.6C70.9,-24,79.3,-12,73.2,-3.5C67.1,4.9,46.4,9.9,38,20.7C29.6,31.5,33.6,48.3,29.3,61.8C25,75.3,12.5,85.7,3.2,80.1C-6,74.5,-12.1,52.9,-26.5,45.2C-40.9,37.5,-63.6,43.7,-66.2,38.5C-68.8,33.2,-51.2,16.6,-50.4,0.4C-49.7,-15.8,-65.8,-31.5,-66.6,-42.6C-67.4,-53.8,-53,-60.2,-39.3,-57.2C-25.7,-54.2,-12.8,-41.7,-2.6,-37.2C7.6,-32.7,15.2,-36.1,25.6,-37.3Z",
      "M33.5,-61.3C39.1,-54.9,36.2,-37.1,35.7,-25C35.2,-12.8,37.1,-6.4,35.5,-0.9C33.8,4.5,28.7,9.1,24.1,12.3C19.5,15.6,15.4,17.5,11.4,19.9C7.5,22.2,3.7,24.9,-0.7,26.2C-5.2,27.5,-10.4,27.3,-15.2,25.5C-20,23.6,-24.3,20.1,-28.8,15.6C-33.2,11,-37.8,5.5,-42,-2.4C-46.2,-10.4,-50.1,-20.8,-50.9,-34.4C-51.7,-48.1,-49.5,-65.1,-40.6,-69.6C-31.6,-74.1,-15.8,-66.2,-0.9,-64.6C14,-63,28,-67.8,33.5,-61.3Z",
      "M28.9,-42.7C42.7,-42.2,62.5,-44.9,68.7,-38.3C74.9,-31.7,67.4,-15.9,57.4,-5.8C47.5,4.4,35,8.7,27.6,13.1C20.2,17.5,17.7,22,14,24C10.2,26,5.1,25.6,0,25.6C-5.1,25.6,-10.2,26,-22.6,29C-35,32,-54.8,37.5,-61,33.1C-67.3,28.7,-60,14.3,-55.2,2.8C-50.4,-8.8,-48,-17.5,-43.4,-24.9C-38.9,-32.3,-32.2,-38.3,-24.6,-42.4C-17,-46.5,-8.5,-48.7,-0.5,-47.9C7.6,-47.1,15.2,-43.3,28.9,-42.7Z",
      "M22.8,-45.3C27,-37,26.3,-25.8,28.9,-17.8C31.5,-9.8,37.5,-4.9,39.4,1.1C41.2,7.1,39,14.2,35.3,20.3C31.6,26.4,26.4,31.6,20.3,41C14.1,50.5,7.1,64.3,-0.5,65.2C-8.1,66,-16.1,54,-19.9,43.1C-23.7,32.3,-23.2,22.7,-26.7,15.7C-30.1,8.8,-37.4,4.4,-47.6,-5.9C-57.9,-16.2,-71,-32.4,-70,-43.6C-69,-54.8,-54,-61.1,-40,-63.9C-26,-66.6,-13,-65.8,-1.9,-62.5C9.3,-59.3,18.5,-53.7,22.8,-45.3Z",
      "M39.4,-65.2C46.9,-63.9,45.9,-44.9,46.3,-31.1C46.6,-17.3,48.3,-8.7,51.3,1.7C54.2,12.1,58.6,24.2,52.8,28.6C47,33,31.1,29.6,20.6,38.3C10.1,47,5.1,67.7,-1.7,70.6C-8.4,73.4,-16.7,58.5,-29.1,50.9C-41.5,43.3,-57.9,43.1,-70.4,35.9C-82.9,28.6,-91.4,14.3,-84.3,4.1C-77.2,-6.1,-54.3,-12.1,-44.9,-24.6C-35.4,-37,-39.2,-55.8,-34,-58.3C-28.7,-60.9,-14.4,-47.2,0.8,-48.5C16,-49.9,31.9,-66.4,39.4,-65.2Z",
      "M23.5,-42.1C33,-35.2,44.9,-34,56.6,-28C68.2,-21.9,79.5,-10.9,83.5,2.3C87.6,15.6,84.4,31.2,75.4,41.9C66.4,52.5,51.6,58.2,38.1,55C24.6,51.8,12.3,39.7,0.9,38.1C-10.4,36.5,-20.8,45.3,-34.4,48.5C-47.9,51.7,-64.6,49.3,-65.6,40.3C-66.6,31.3,-51.8,15.7,-52.6,-0.5C-53.4,-16.6,-69.7,-33.1,-68,-40.8C-66.3,-48.5,-46.4,-47.3,-32.1,-51.3C-17.7,-55.3,-8.9,-64.6,-0.9,-63C7,-61.4,14,-48.9,23.5,-42.1Z",
      "M32.6,-59.9C38.2,-53.3,35.7,-36.3,42.9,-24.5C50.1,-12.8,67,-6.4,66.3,-0.4C65.5,5.5,47.2,11.1,36.5,16.8C25.8,22.5,22.8,28.4,18,39C13.2,49.7,6.6,65,-1.2,67.2C-9.1,69.3,-18.2,58.2,-27.4,50.2C-36.7,42.1,-46.1,37.1,-57.2,29.2C-68.4,21.4,-81.2,10.7,-77.9,1.9C-74.6,-6.9,-55.1,-13.7,-41.7,-17.6C-28.2,-21.4,-20.7,-22.2,-14.8,-28.6C-8.8,-35,-4.4,-46.9,4.6,-54.8C13.5,-62.7,27.1,-66.5,32.6,-59.9Z",
      "M40.7,-66.6C50.9,-64.5,56,-49.9,57.4,-36.7C58.7,-23.6,56.2,-11.8,54,-1.2C51.9,9.3,50.1,18.6,47.8,30.2C45.6,41.8,42.9,55.6,34.8,57.3C26.8,59,13.4,48.5,3.5,42.3C-6.3,36.2,-12.6,34.4,-24.5,35C-36.5,35.6,-54.2,38.5,-60.3,33.1C-66.4,27.6,-61,13.8,-53.7,4.2C-46.5,-5.4,-37.3,-10.8,-32.1,-17.8C-26.8,-24.8,-25.5,-33.3,-20.8,-38.5C-16.1,-43.8,-8,-45.7,3.6,-52C15.2,-58.2,30.5,-68.7,40.7,-66.6Z",
      "M23.1,-39.9C33.8,-33.9,48.7,-35.4,59.3,-30C69.8,-24.5,75.8,-12.3,79.5,2.2C83.3,16.6,84.8,33.2,74.7,39.3C64.6,45.5,42.9,41.1,28.5,45.4C14.1,49.7,7.1,62.7,-4.7,70.8C-16.5,78.9,-32.9,82.2,-42,74.9C-51.1,67.5,-52.7,49.5,-57.2,35.2C-61.6,20.9,-68.8,10.5,-67,1C-65.2,-8.4,-54.3,-16.7,-48.7,-28.9C-43.1,-41.1,-42.7,-57.1,-35.4,-65.1C-28.2,-73,-14.1,-72.8,-3.9,-66C6.3,-59.3,12.5,-45.9,23.1,-39.9Z",
      "M32.7,-49C46,-48.9,63,-47.6,73.8,-39.2C84.5,-30.8,88.9,-15.4,83.7,-3C78.5,9.4,63.8,18.9,55.6,31.7C47.5,44.5,46,60.8,37.8,60.7C29.6,60.5,14.8,44,3.1,38.7C-8.6,33.3,-17.3,39.1,-27.6,40.5C-37.9,41.8,-49.8,38.7,-54.4,31.3C-59,23.8,-56.2,11.9,-60.4,-2.4C-64.5,-16.6,-75.5,-33.3,-72,-42.7C-68.5,-52,-50.4,-54.1,-36,-53.6C-21.6,-53.2,-10.8,-50.2,-0.6,-49.2C9.7,-48.2,19.3,-49.2,32.7,-49Z",
    ];

    const path = pathArray[Math.floor(Math.random() * pathArray.length)];

    return path;
  };

  const containerStyle = {
    whiteSpace: "pre-line",
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    return () => window.removeEventListener("scroll", checkVisibility);
  }, []);

  useEffect(() => {
    // Function to check screen width and update hover states
    const updateHoverStatesForMobile = () => {
      const screenWidth = window.innerWidth;
      console.log(screenWidth);
      if (screenWidth < 768) {
        // 768px is a common breakpoint for mobile devices
        const intervalId = setInterval(() => {
          setPathData(generateRandomPath());
        }, 1000);

        const intervalId2 = setInterval(() => {
          setPathData2(generateRandomPath());
        }, 1000);

        const intervalId3 = setInterval(() => {
          setPathData3(generateRandomPath());
        }, 1000);

        const intervalId4 = setInterval(() => {
          setPathData4(generateRandomPath());
        }, 1000);
      }
    };

    // Call the function on initial render
    updateHoverStatesForMobile();

    // Add an event listener to update states when window is resized
    window.addEventListener("resize", updateHoverStatesForMobile);

    // Cleanup event listener on component unmount
    return () =>
      window.removeEventListener("resize", updateHoverStatesForMobile);
  }, []);

  useEffect(() => {
    if (!isLoremVisible || completedTypingLorem) return;

    let i = 0;
    const intervalId = setInterval(() => {
      setDisplayLorem(loremText.slice(0, i));
      i++;
      if (i > loremText.length) {
        clearInterval(intervalId);
        setCompletedTypingLorem(true);
      }
    }, 20);

    return () => clearInterval(intervalId);
  }, [isLoremVisible, loremText]);

  useEffect(() => {
    setCompletedTyping(false);

    let i = 0;
    const stringResponse = heroText[heroText.length - 1].content;

    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse.slice(0, i));

      i++;

      if (i > stringResponse.length) {
        clearInterval(intervalId);
        setCompletedTyping(true);
      }
    }, 20);

    return () => clearInterval(intervalId);
  }, [heroText]);

  return (
    <section className="bg-gray-black">
      {/* center and full screne an image div  */}
      <div className="relative flex justify-center">
        <div className="flex justify-center">
          <div className="flex justify-center my-32">
            <span className="bg-gray-black text-center text-white lg:text-6xl md:text-5xl sm:text-3xl font-bold p-4 rounded-2xl px-16 min-w-full">
              {displayResponse}
              {!completedTyping && (
                <svg
                  viewBox="8 4 8 16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor"
                >
                  <rect x="10" y="6" width="4" height="12" fill="#fff" />
                </svg>
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-8 mt-32 lg:mx-32 md:mx-8 sm:mx-8">
        <div
          className="border-4 border-purple-gradient1 rounded-xl flex justify-center items-center"
          data-aos="fade-up"
          // send user to quasi.ventures
          onClick={() => window.location.replace("/demos")}
          onMouseEnter={() => {
            setPathData(generateRandomPath());
            const intervalId = setInterval(() => {
              setPathData(generateRandomPath());
            }, 1000);
            setIsHovered(true);
            // Store the interval ID in a ref or state, so it can be accessed in onMouseLeave
            setIntervalId(intervalId);
          }}
          onMouseLeave={() => {
            clearInterval(intervalId); // Clear the interval when mouse leaves
            setIsHovered(false);
          }}
        >
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#FF51A0"
              d={pathData}
              className="blob-transition"
              transform="translate(100 100)"
            />
          </svg>
          <span
            className={`absolute text-white text-4xl font-bold ${
              isHovered ? "underline" : ""
            }`}
          >
            Demos
          </span>
        </div>

        <div
          className="border-4 border-blue-gradient1 rounded-xl flex justify-center items-center"
          data-aos="fade-up"
          onClick={() => window.location.replace("/about")}
          onMouseEnter={() => {
            setPathData2(generateRandomPath());
            const intervalId2 = setInterval(() => {
              setPathData2(generateRandomPath());
            }, 1000);
            setIsHovered2(true);
            // Store the interval ID in a ref or state, so it can be accessed in onMouseLeave
            setIntervalId2(intervalId2);
          }}
          onMouseLeave={() => {
            clearInterval(intervalId2); // Clear the interval when mouse leaves
            setIsHovered2(false);
          }}
        >
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#7AF3E1"
              d={pathData2}
              className="blob-transition"
              transform="translate(100 100)"
            />
          </svg>
          <span
            className={`absolute text-black text-4xl font-bold ${
              isHovered2 ? "underline" : ""
            }`}
          >
            About
          </span>
        </div>

        <div
          className="border-4 border-orange-gradient1 rounded-xl flex justify-center items-center"
          data-aos="fade-up"
          onClick={() => window.location.replace("/gallery")}
          onMouseEnter={() => {
            setPathData3(generateRandomPath());
            const intervalId3 = setInterval(() => {
              setPathData3(generateRandomPath());
            }, 1000);
            setIsHovered3(true);
            // Store the interval ID in a ref or state, so it can be accessed in onMouseLeave
            setIntervalId3(intervalId3);
          }}
          onMouseLeave={() => {
            clearInterval(intervalId3); // Clear the interval when mouse leaves
            setIsHovered3(false);
          }}
        >
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#ff9812"
              d={pathData3}
              className="blob-transition"
              transform="translate(100 100)"
            />
          </svg>
          <span
            className={`absolute text-white text-4xl font-bold ${
              isHovered3 ? "underline" : ""
            }`}
          >
            Gallery
          </span>
        </div>

        <div
          className="border-4 border-gray-white rounded-xl flex justify-center items-center"
          data-aos="fade-up"
          onClick={() => window.location.replace("/blog")}
          onMouseEnter={() => {
            setPathData4(generateRandomPath());
            const intervalId4 = setInterval(() => {
              setPathData4(generateRandomPath());
            }, 1000);
            setIsHovered4(true);
            // Store the interval ID in a ref or state, so it can be accessed in onMouseLeave
            setIntervalId4(intervalId4);
          }}
          onMouseLeave={() => {
            clearInterval(intervalId4); // Clear the interval when mouse leaves
            setIsHovered4(false);
          }}
        >
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#FFFFFF"
              d={pathData4}
              className="blob-transition"
              transform="translate(100 100)"
            />
          </svg>
          <span
            className={`absolute text-black text-4xl font-bold ${
              isHovered4 ? "underline" : ""
            }`}
          >
            Blog
          </span>
        </div>
      </div>

      <div className="flex justify-center py-64 md:mx-8 w-full">
        <div
          className="min-w-full bg-gray-black text-white p-8 rounded-xl md:px-24 sm:px-8"
          id="loremText"
        >
          <div className="flex flex-col">
            <span className="text-6xl text-white font-semibold">About</span>
            <div className="" style={containerStyle}>
              {displayLorem}
              {!completedTypingLorem && (
                <svg
                  viewBox="8 4 8 16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor"
                >
                  <rect x="10" y="6" width="4" height="12" fill="#fff" />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewHomePartial;
