import React from "react";
import { useEffect } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AboutPartial from "../partials/AboutPartial";

const About = (props) => {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>GPTEZ</title>
      <meta name="description" content="Free GPT-4!" />
      <meta property="og:title" content="GPTEZ" />
      <Header />
      {/*  Page content */}
      <main className="flex-grow">
        <AboutPartial />
      </main>
      <Footer />
    </div>
  );
};

export default About;
