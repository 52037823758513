import React, { useEffect } from "react";
import { Route, Routes, Redirect } from "react-router-dom";
import "./css/style.scss";
import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";
// Landing pages:
import Home from "./pages/Home";
import Agent from "./pages/Agent";

// Required StyleSheets
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "firebase/auth";
import NewHome from "./pages/NewHome";
import APIDocs from "./pages/APIDocs";
import Blog from "./pages/Blog";
import Gallery from "./pages/Gallery";
import About from "./pages/About";
import Demo from "./pages/Demo";
import Source from "./pages/Source";
import Optimizer from "./pages/Optimizer";
import Predictor from "./pages/Predictor";

const App = (props) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, []);

  return (
    <Routes>
      <Route exact path="/" element={<NewHome />} />
      <Route exact path="/chat" element={<Agent />} />
      <Route exact path="/optimizer" element={<Optimizer />} />
      <Route exact path="/api" element={<APIDocs />} />
      <Route exact path="/blog" element={<Blog />} />
      <Route exact path="/gallery" element={<Gallery />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/demos" element={<Demo />} />
      <Route exact path="/source" element={<Source />} />
      <Route exact path="/predictor" element={<Predictor />} />
    </Routes>
  );
};

export default App;
