import React from "react";
import { useEffect } from "react";
import ChatPartial from "../partials/ChatPartial";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

const Agent = (props) => {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="bg-gray-medium h-full">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>GPTEZ</title>
      <meta name="description" content="Free GPT-4!" />
      <meta property="og:title" content="GPTEZ" />

      {/*  Page content */}
      <main className="h-full flex-grow">
        {/* <Header /> */}
        <ChatPartial />
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default Agent;
