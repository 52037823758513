import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";

function BlogPartial() {
  return (
    <section className="flex justify-center bg-gray-black">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#FFFFFF"
            fill-opacity="1"
            d="M0,32L40,42.7C80,53,160,75,240,69.3C320,64,400,32,480,26.7C560,21,640,43,720,64C800,85,880,107,960,138.7C1040,171,1120,213,1200,197.3C1280,181,1360,107,1400,69.3L1440,32L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
          ></path>
        </svg>
        <div className="flex">
          <div className="md:px-24 sm:px-6 mb-16">
            <div className="flex justify-center">
              <div className="flex flex-col">
                <div className="lg:text-6xl sm:text-4xl font-bold text-white">
                  Thomas' Blog
                </div>
                <div className="text-white pt-8">
                  Just my throughts on the AI industry
                </div>
                <div className="mt-16 text-white">
                  <div
                    className="bg-gray-black border-4 border-gray-white p-4 rounded-2xl my-8 flex flex-col"
                    id="blog3"
                  >
                    <span className="font-bold text-xl">January 2nd, 2024</span>

                    <span className="my-2">
                      AI routing (or is it AI orchistration) may be the next AI
                      frontier. Let me explain 👇
                    </span>
                    <span className="my-2">
                      First off what is AI routing? Well as more and more LLMs
                      break into the market from companies like Microsoft,
                      Facebook, Hugging Face, Amazon, Mistral and many other, AI
                      devs need to know which AI is best for them, they need
                      their API requests to be "Routed" to the optimal model
                    </span>
                    <span className="my-2">
                      Imagine a traditional telephone switchboard operator. This
                      operator represents the AI routing system. In front of the
                      operator is a large panel with numerous cords and plugs,
                      each leading to a different telephone line. These lines
                      symbolize the various LLMs available from different
                      companies.{" "}
                    </span>
                    <span className="my-2">
                      When a call comes in (akin to an API request), the
                      operator listens to the caller's request (the nature of
                      the API query). Based on the request's specifics - such as
                      language complexity, subject matter, required processing
                      power, or even cost-effectiveness - the operator decides
                      which line (LLM) is most suitable for this call. The
                      operator then plugs the call into the appropriate line,
                      effectively 'routing' the call to the most suitable
                      language model.
                    </span>
                    <span className="my-2">
                      Just as the switchboard operator uses their knowledge and
                      the information provided by the caller to connect them to
                      the right line, the AI routing system uses algorithms and
                      data about each model's capabilities to direct API
                      requests to the most appropriate LLM. This ensures that
                      each query is handled by the model best equipped to
                      provide an accurate and efficient response, much like
                      ensuring a caller is connected to the right department or
                      person for their query.
                    </span>
                    <span className="my-2">
                      So how do you make an AI router? Well I'm not sure yet but
                      here are some ideas I've been working on. First off
                      develope an LLM ranking system similar to{" "}
                      <a
                        className="hover:underline"
                        href="https://tatsu-lab.github.io/alpaca_eval/"
                        target="_blank"
                      >
                        Alpaca Eval
                      </a>
                    </span>
                    <span className="my-2">
                      This system, when ranking an LLM, would send a single
                      prompt requestion (in the form of a chat question) to all
                      models and get a unique but similar responce from each.
                      Then a new prompt will be submitted to all models with the
                      task of ranking the quality of each responce excluding the
                      responce from the model itself. Lastly these rankings are
                      averaged (or something similar) and used to train a
                      standard neural net
                    </span>
                    <span className="my-2">
                      The next thing that will be needed to make this router is
                      proper pricing calculations. LLMs pricing is all over the
                      place and when routing requests, pricing needs to be taken
                      into account in order to get the best price for
                      performance. I've compiled an LLM pricing spread sheet
                      last updated on New Years 2024.{" "}
                      <a
                        className="hover:underline"
                        href="https://laser-juice-e01.notion.site/LLM-Costs-By-Hosting-Platforms-39d7189d08e045899d975c408dd0c73c"
                        target="_blank"
                      >
                        Take a Look!
                      </a>
                    </span>
                    <span className="my-2">
                      Anyways, I'm activly working on this problem so If you
                      have any ideas, feedback or want to chat about this feel
                      free to email me! thomas@quasi.ventures (or DM me on 𝕏 🙅
                      @T0M_3D)
                    </span>
                  </div>
                </div>
                <div className="mt-16 text-white">
                  <div
                    className="bg-gray-black border-4 border-gray-white p-4 rounded-2xl my-8 flex flex-col"
                    id="blog1"
                  >
                    <span className="font-bold text-xl">August 3rd, 2023</span>
                    <span className="my-2">
                      <a
                        className="hover:underline"
                        href="https://github.com/Significant-Gravitas/Auto-GPT"
                        target="_blank"
                      >
                        AutoGPT
                      </a>
                      ... if your in the AI industry you've probably heard of it
                      (it has 145k stars on github 🤯)
                    </span>
                    <span className="my-2">
                      I've tried out a few repos and demos from my friends and
                      the technique looks promissing. Essentially you prompt
                      your LLM to come up with a list of exicutible tasks and
                      autonomously execute each task. The outputs from these
                      sort of tasks can range from simple text to files or even
                      website (data collected during said task)
                    </span>
                    <span className="my-2">
                      There are some clear limitations tho: <br /> 1. LLMs dont
                      understand how long a task should take, as of now AutoGPT
                      can run indefinitely or exicute way more tasks than needed
                      (waisting precious tokens)
                      <br /> 2. If just one task fails the whole process fails
                      <br /> 3. LLMs are very arogant (they dont know what they
                      dont know and will assume to know everything)
                      <br /> 4. Internet access isnt enough. Many tasks that add
                      value need credentials (for example auto responding to
                      emails)
                    </span>
                    <span className="my-2">
                      From my experience problem 1 and 2 can be solved with fine
                      tuneing and general gpt improvments that hopefully will
                      continue to happen
                    </span>
                    <span className="my-2">
                      Problem 3 and 4 are a bit more challenging. How do you
                      tech an LLM to know when it doesnt know something? and
                      once you do that, how do you have it prompt a humans for
                      help in its knowledge or experience gap?
                    </span>
                    <span className="my-2">
                      I've tried a few things (like fine-tuning another LLM to
                      check the work of the first LLM or adding task checkers to
                      the AutoGPT task lists) but nothing has worked well
                      consistently enough for me.
                    </span>
                    <span className="my-2">
                      I'll keep cooking on this problem. Expect updates to GPTEZ
                      soon!
                    </span>
                    <span className="my-2">
                      If you have any ideas, feedback or want to chat about this
                      feel free to email me! thomas@quasi.ventures (or DM me on
                      𝕏 🙅 @T0M_3D)
                    </span>
                  </div>
                </div>
                <div className="mt-16 text-white">
                  <div
                    className="bg-gray-black border-4 border-gray-white p-4 rounded-2xl my-8 flex flex-col"
                    id="blog2"
                  >
                    <span className="font-bold text-xl">July 30, 2023</span>
                    <span className="my-2">
                      Now in all honesty I could have chatGPT write this but
                      then it wouldnt have nearly as many typos (or would it).
                      I'm writting this blog mostly for me to jot down my ideas
                      and thoughts in my quest to find value in this new AI
                      industry
                    </span>
                    <span className="my-2">
                      Today most of the large AI players are well established
                      (the googles and microsofts of the world) and are
                      integrating AI into their existing products to make them
                      better. This kind of value add is only accessible to these
                      companies since not everyone can add AI to excel of google
                      for example.
                    </span>
                    <span className="my-2">
                      On the backend companies like openAI and Anthropic are
                      dominating. New companies like Stability and Midjourney
                      have also bursted onto the stage and have sucessfully
                      cultivated an audience for their products. This Layer of
                      the AI stack is the newest and where most of the hype is
                      going at the moment but I dont see that lasting
                    </span>
                    <span className="my-2">
                      Nowadays anyone can make GPT clone, theres pleanty of
                      tutorials on the Youtube on how to do it and the taks
                      itself it very well defined. The problem isnt training
                      these models but rather runing them at scale. Right now
                      Azure has a chokehold on AI infrastructure at least for
                      the next few years. I see them making alot of money from
                      this investment as new companies run new models on their
                      service
                    </span>
                    <span className="my-2">
                      How many GPT clones do we need tho??? GPT-3, Claude, and
                      LLAMA-2 are essentially all the same, you give em some
                      tokens and they give you the next tokens. Their main
                      competitive advantages right now are cost. GPT-3 per token
                      prices have already gone down three times this year and I
                      can imagine costs will continue to decline rapidly. This
                      is great for people who want to use these models but not
                      so great for the companies that make them.
                    </span>
                    <span className="my-2">
                      What are people using LLMs for anyways? For the amount of
                      hype in the industry right now, shockingly little. Aside
                      from one... ChatGPT is obviously extreamly popular and
                      easy to use. So much so it destroyed the value props of
                      Chegg, Jasper, and Google. But what else?
                    </span>
                    <span className="my-2">
                      I could link like 10 ChatGPT clones, I mean your
                      essentially on one now. The question is what exists beyone
                      ChatGPT? Thats what I'm trying to figure out... stay tuned
                      for more!!!
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* sidebard only visible on large screens */}
          <Sidebar />
        </div>
      </div>
    </section>
  );
}

export default BlogPartial;
