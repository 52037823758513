// this file will contain the Axios setup link between API and Front-end
import firebase from "firebase/app";
import "firebase/auth";
import merlinApp from "../AxiosSetup.js";

// POST request functions

export const chat = async (messageId, flattenedMessages) => {
  const response = await fetch(
    "https://public-demo-endpoints.azurewebsites.net/chat_agent",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message_id: messageId,
        flatten_messages: flattenedMessages,
      }),
    }
  );

  return response.body.getReader();
};

export const new_agent = async (agent) => {
  return merlinApp.post("/new_agent", {
    agent: agent,
  });
};

export const get_agent = async (agentId) => {
  return merlinApp.post("/get_agent", {
    agent_id: agentId,
  });
};

export const new_chat = async (agentId, agent) => {
  return merlinApp.post("/new_chat", {
    agent_id: agentId,
    agent: agent,
  });
};

export const get_chat = async (agentId, chatId, agent) => {
  return merlinApp.post("/get_chat", {
    chat_id: chatId,
    agent_id: agentId,
    agent: agent,
  });
};

export const delete_chat = async (agentId, chatId, agent) => {
  return merlinApp.post("/delete_chat", {
    agent_id: agentId,
    chat_id: chatId,
    agent: agent,
  });
};

export const delete_data = async (agentId, dataId, agent) => {
  return merlinApp.post("/delete_data", {
    agent_id: agentId,
    data_id: dataId,
    agent: agent,
  });
};

export const upload_file = async (file, fileName, agentId, agent) => {
  return merlinApp.post("/upload_file", {
    file: file,
    file_name: fileName,
    agent_id: agentId,
    agent: agent,
  });
};

export const get_all_agents = async () => {
  return merlinApp.get("/get_all_agents");
};

export const regenerate = async (agentId, chatId, messageId, newMessageId) => {
  const response = await fetch(
    "https://public-demo-endpoints.azurewebsites.net/regenerate",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        agent_id: agentId,
        message_id: messageId,
        chat_id: chatId,
        new_message_id: newMessageId,
      }),
    }
  );

  return response.body.getReader();
};

export const edit = async (
  message,
  agentId,
  currentMessageId,
  parentMessageId,
  chatId,
  humanMessageId,
  aiMessageId
) => {
  const response = await fetch(
    "https://public-demo-endpoints.azurewebsites.net/edit",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: message,
        agent_id: agentId,
        message_id: currentMessageId,
        parent_message_id: parentMessageId,
        chat_id: chatId,
        human_message_id: humanMessageId,
        ai_message_id: aiMessageId,
      }),
    }
  );

  return response.body.getReader();
};

export const find_sources = async (query) => {
  return merlinApp.post("/find_sources", {
    query: query,
  });
};

export const generate_responses = async (prompt) => {
  return merlinApp.post("/generate_responses", {
    prompt: prompt,
  });
};

export const predict_tokens = async (prompt, n) => {
  return merlinApp.post("/predict_tokens", {
    prompt: prompt,
    n: n,
  });
};
