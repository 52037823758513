import React from "react";

function Sidebar() {
  return (
    <aside className="bg-gray-black text-white w-1/4 p-4 relative hidden lg:block">
      {/* Add a vertical white line */}
      <div className="absolute left-0 top-0 h-full w-1 rounded-full bg-white"></div>
      <div className="flex flex-row">
        <div className="text-2xl font-bold mb-4 mr-12">Content</div>
        <svg
          width="26"
          height="23"
          viewBox="0 0 26 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8613 22.6035H18.0195C18.9863 22.6035 19.5332 22.0469 19.5332 21.0898V7.32031L21.0664 21.3145C21.1641 22.2715 21.7695 22.7598 22.7266 22.6523L24.123 22.4668C25.0801 22.3594 25.5586 21.7441 25.4609 20.7969L23.7422 5.07422C23.6445 4.11719 23.0391 3.62891 22.0723 3.73633L20.6758 3.92188C20.1387 3.98047 19.748 4.20508 19.5332 4.55664V2.33984C19.5332 1.38281 18.9863 0.826172 18.0195 0.826172H15.8613C14.8945 0.826172 14.3477 1.38281 14.3477 2.33984V21.0898C14.3477 22.0469 14.8945 22.6035 15.8613 22.6035ZM2.04297 22.6035H3.83984C4.80664 22.6035 5.35352 22.0469 5.35352 21.0898V5.13281C5.35352 4.17578 4.80664 3.61914 3.83984 3.61914H2.04297C1.07617 3.61914 0.529297 4.17578 0.529297 5.13281V21.0898C0.529297 22.0469 1.07617 22.6035 2.04297 22.6035ZM8.03906 22.6035H11.6621C12.6289 22.6035 13.1758 22.0469 13.1758 21.0898V8.67773C13.1758 7.7207 12.6289 7.16406 11.6621 7.16406H8.03906C7.07227 7.16406 6.52539 7.7207 6.52539 8.67773V21.0898C6.52539 22.0469 7.07227 22.6035 8.03906 22.6035ZM8.85938 10.9238C8.46875 10.9238 8.16602 10.6211 8.16602 10.2305C8.16602 9.84961 8.45898 9.55664 8.85938 9.55664H10.8613C11.291 9.55664 11.5547 9.84961 11.5547 10.2305C11.5547 10.6211 11.252 10.9238 10.8613 10.9238H8.85938ZM8.85938 20.2207C8.45898 20.2207 8.16602 19.918 8.16602 19.5273C8.16602 19.1465 8.46875 18.8438 8.85938 18.8438H10.8613C11.252 18.8438 11.5547 19.1465 11.5547 19.5273C11.5547 19.918 11.252 20.2207 10.8613 20.2207H8.85938Z"
            fill="white"
          />
        </svg>
      </div>
      <ul>
        <li className="mb-2">
          <a href="#blog3" className="hover:underline">
            AI Routing
          </a>
        </li>
        <li className="mb-2">
          <a href="#blog1" className="hover:underline">
            AutoGPT
          </a>
        </li>
        <li className="mb-2">
          <a href="#blog2" className="hover:underline">
            LLM Value Add
          </a>
        </li>
        {/* Add more blog links here */}
      </ul>
    </aside>
  );
}

export default Sidebar;
