import React, { useEffect, useState } from "react";

function APIDocsPartial() {
  return (
    <section className='flex justify-center'>
      <div className='md:px-24 sm:px-6 mb-16'>
        <div className='flex justify-center mt-8'>
          <div className='flex flex-col'>
            <div className='text-3xl font-semibold'>
              Jack's API Documentation
            </div>
            <div>if your not jack please ignore</div>
            <div className='mt-16'>
              <div className='my-8'>
                <div className='font-bold text-xl'>/health [GET]</div>
                <div>RETURNS "success": True,</div>
              </div>
              <div className='my-8'>
                <div className='font-bold text-xl'>/new_agent [POST]</div>
                <div>TAKES agent (json)</div>
                <div>RETURNS "success": True, "agent_id": string</div>
              </div>
              <div className='my-8'>
                <div className='font-bold text-xl'>/get_agent [POST]</div>
                <div>TAKES agent_id (string)</div>
                <div>RETURNS "success": True, "agent": json</div>
              </div>
              <div className='my-8'>
                <div className='font-bold text-xl'>/new_chat [POST]</div>
                <div>TAKES agent_id (string), agent (json)</div>
                <div>RETURNS "success": True, "chats": list, "agent": json</div>
              </div>
              <div className='my-8'>
                <div className='font-bold text-xl'>/get_chat [POST]</div>
                <div>TAKES agent_id (string), agent (json)</div>
                <div>
                  RETURNS "success": True, "messages": list, "agent": json
                </div>
              </div>
              <div className='my-8'>
                <div className='font-bold text-xl'>/delete_chat [POST]</div>
                <div>TAKES agent_id (string), agent (json)</div>
                <div>RETURNS "success": True, "chats": list, "agent": json</div>
              </div>
              <div className='my-8'>
                <div className='font-bold text-xl'>/delete_data [POST]</div>
                <div>TAKES agent_id (string), agent (json), data_id (int)</div>
                <div>RETURNS "success": True, "docs": list, "agent": json</div>
              </div>
              <div className='my-8'>
                <div className='font-bold text-xl'>/chat_agent [POST]</div>
                <div>
                  TAKES agent_id (string), agent (json), chat_id (int), messages
                  (list), message (string)
                </div>
                <div>
                  RETURNS "success": True, "output": string, "docs": list,
                  "agent": json
                </div>
              </div>
              <div className='my-8'>
                <div className='font-bold text-xl'>/upload_file [POST]</div>
                <div>
                  TAKES agent_id (string), agent (json), file (base64 encoding),
                  file_name (string)
                </div>
                <div>RETURNS "success": True, "docs": list, "agent": json</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default APIDocsPartial;
