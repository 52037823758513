import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";

function DemoPartial() {
  return (
    <section className="flex justify-center bg-gray-black">
      <div className="w-full">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#FF51A0"
            fill-opacity="1"
            d="M0,96L48,122.7C96,149,192,203,288,213.3C384,224,480,192,576,160C672,128,768,96,864,90.7C960,85,1056,107,1152,122.7C1248,139,1344,149,1392,154.7L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
        <div className="flex">
          <div className="md:px-24 sm:px-6 mb-16">
            <div className="flex justify-center">
              <div className="flex flex-col">
                <div
                  className="lg:text-6xl sm:text-4xl font-bold text-white"
                  data-aos="fade-up"
                >
                  Demos
                </div>
                <div className="text-white pt-8" data-aos="fade-up">
                  Here are some demos I'm working on. Please send feedback to
                  thomas@quasi.ventures
                </div>
                <div className="grid grid-cols-2 grid-rows-2 gap-8 pt-16">
                  <div
                    className="border-4 border-gray-white hover:border-purple-gradient1 rounded-xl aos-init aos-animate p-4"
                    onClick={() => window.location.replace("/optimizer")}
                  >
                    <div className="lg:text-5xl sm:text-3xl font-bold text-white">
                      Prompt Optimizer 🤖
                    </div>
                    <div className="text-white">
                      Takes a prompt and generates optimal variations of said
                      prompt
                    </div>
                  </div>
                  <div
                    className="border-4 border-gray-white hover:border-purple-gradient1 rounded-xl aos-init aos-animate p-4"
                    onClick={() => window.location.replace("/source")}
                  >
                    <div className="lg:text-5xl sm:text-3xl font-bold text-white">
                      Source Finder ✍️
                    </div>
                    <div className="text-white">
                      Given an LLM output, attempts to find verbatim sources in
                      its training data and give credit to originial authors
                    </div>
                  </div>
                  <div
                    className="border-4 border-gray-white hover:border-purple-gradient1 rounded-xl aos-init aos-animate p-4"
                    onClick={() => window.location.replace("/predictor")}
                  >
                    <div className="lg:text-5xl sm:text-3xl font-bold text-white">
                      Token Predictor 🔮
                    </div>
                    <div className="text-white">
                      Predicts the amount of token any given prompt will
                      generate
                    </div>
                  </div>
                  <div
                    className="border-4 border-gray-white hover:border-purple-gradient1 rounded-xl aos-init aos-animate p-4"
                    // on click send user to quasi.market/radio in a new tab
                    onClick={() =>
                      window.open("https://quasi.market/radio", "_blank")
                    }
                  >
                    <div className="lg:text-5xl sm:text-3xl font-bold text-white">
                      AI Radio Station🕺
                    </div>
                    <div className="text-white">
                      Generates and plays AI generated music
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-8 pt-8">
                  <div
                    className="border-4 border-gray-white hover:border-purple-gradient1 rounded-xl aos-init aos-animate p-4"
                    onClick={() => window.location.replace("/chat")}
                  >
                    <div className="lg:text-5xl sm:text-3xl font-bold text-white">
                      Chat 💡
                    </div>
                    <div className="text-white">
                      Standard chat interface with dynamic system prompt
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DemoPartial;
