import React, { useState, useEffect, useRef } from "react";
import { generate_responses } from "../merlinv1/api";
import ReactMardown from "react-markdown";

const OptimizePartial = React.forwardRef((props, ref) => {
  let searchParams = new URLSearchParams(window.location.pathname);
  var currentMessageId = 0;

  const [renderKey, setRenderKeyForMessages] = useState(0);
  const [userMessage, setUserMessage] = useState("");
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  //   const [derivatives, setDerivatives] = useState([]);
  //   const [derivativePrompts, setDerivativePrompts] = useState([]);
  //   const [treeStates, setTreeStates] = useState([]);

  const [promptStateManager, setPromptStateManager] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const optimizePrompt = async (cur_prompt) => {
    try {
      setLoading(true);

      if (prompt === "") {
        setPrompt(cur_prompt);
      }

      var newStateManager = promptStateManager;
      newStateManager.push([[], [], []]);
      setPromptStateManager(newStateManager);

      var res = await generate_responses(cur_prompt);
      console.log(res);
      var responses = res.data.responses;
      var prompts = res.data.prompts;

      // last index of the stateprompt manager gets updated with new responses
      var newStateManager = promptStateManager;
      newStateManager[newStateManager.length - 1][0] = responses;
      newStateManager[newStateManager.length - 1][1] = prompts;
      newStateManager[newStateManager.length - 1][2] = [false, false]; //set both prompt selection to false
      setPromptStateManager(newStateManager);

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="relative z-0 flex h-full w-full overflow-hidden">
      <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
        <main className="relative h-full w-full flex-1 overflow-auto transition-width">
          <div
            role="presentation"
            className="flex h-full flex-col bg-gray-black"
          >
            <div className="flex-1 overflow-hidden">
              {prompt === "" ? (
                <div class="relative h-full">
                  <div class="absolute left-0 right-0">
                    <div class="h-1.5"></div>
                  </div>
                  <div class="flex h-full flex-col items-center justify-center">
                    <div class="relative">
                      <div class="mb-3 h-[72px] w-[72px]">
                        <div className="flex flex-col">
                          <span className="text-5xl">
                            <img src="https://storage.googleapis.com/quasi-a39a6.appspot.com/White%20Nabla.png"></img>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="mb-5 text-2xl font-medium text-white">
                      Lets optimize some prompts!
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" overflow-y-auto h-full" id="scrollableDiv">
                  <div className="react-scroll-to-bottom--css-uutfu-1n7m0yu">
                    <div className="flex flex-col pb-9 text-sm">
                      <div key={renderKey}>
                        <div className="my-2">
                          <div class="message-content">
                            <div
                              class="w-full text-gray-white"
                              data-testid="conversation-turn-2"
                            >
                              <div class="group px-4 py-2 justify-center text-base md:gap-6 m-auto">
                                <div class="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] }">
                                  <div class="flex-shrink-0 flex flex-col relative items-end">
                                    <div>
                                      <div class="pt-0.5">
                                        <div class="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                                          <div class="relative flex">👤</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="relative flex w-full flex-col lg:w-[calc(100%-115px)]">
                                    <div class="font-semibold select-none">
                                      You
                                    </div>
                                    <div class="flex-col gap-1 md:gap-3">
                                      <div>
                                        <div class="flex flex-grow flex-col max-w-full">
                                          <div
                                            data-message-author-role="user"
                                            data-message-id="aaa2b2ea-4c83-4331-9548-43f33f713205"
                                            class="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto"
                                          >
                                            <div class="">{prompt}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {promptStateManager.map((derivative, index) => (
                          <div className="my-2">
                            <div class="message-content">
                              <div class="group w-full text-gray-white">
                                <div class="px-4 py-2 justify-center text-base md:gap-6 m-auto">
                                  <div class="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] }">
                                    <div class="flex-shrink-0 flex flex-col relative items-end">
                                      <div>
                                        <div class="pt-0.5">
                                          <div class="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                                            <div class="relative p-1 rounded-sm h-9 w-9 text-white flex items-center justify-center">
                                              🤖
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="relative flex w-full flex-col lg:w-[calc(100%-115px)] agent-turn">
                                      <div class="font-semibold select-none">
                                        Optimizer
                                      </div>
                                      <div class="flex-col gap-1 md:gap-4">
                                        <div class="flex flex-grow flex-col max-w-full">
                                          <div class="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto">
                                            <div class="markdown prose w-full break-words text-white">
                                              {derivative[0].length === 0 ? (
                                                <p>...</p>
                                              ) : (
                                                <div className="grid grid-cols-2 gap-4">
                                                  <div
                                                    className={
                                                      derivative[2][0]
                                                        ? "border-4 border-purple-gradient1 rounded-xl aos-init aos-animate p-4"
                                                        : "border-4 border-gray-white hover:border-purple-gradient1 rounded-xl aos-init aos-animate p-4"
                                                    }
                                                    onClick={() =>
                                                      optimizePrompt(
                                                        derivative[1][0]
                                                      )
                                                    }
                                                  >
                                                    <ReactMardown>
                                                      {derivative[0][0]}
                                                    </ReactMardown>
                                                  </div>
                                                  <div
                                                    className={
                                                      derivative[2][1]
                                                        ? "border-4 border-purple-gradient1 rounded-xl aos-init aos-animate p-4"
                                                        : "border-4 border-gray-white hover:border-purple-gradient1 rounded-xl aos-init aos-animate p-4"
                                                    }
                                                    onClick={() =>
                                                      optimizePrompt(
                                                        derivative[1][1]
                                                      )
                                                    }
                                                  >
                                                    <ReactMardown>
                                                      {derivative[0][1]}
                                                    </ReactMardown>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="w-full dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)]">
              <form className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
                <div className="relative flex h-full flex-1 items-stretch md:flex-col">
                  <div className="flex w-full items-center">
                    <div className="overflow-hidden [&amp;:has(textarea:focus)]:border-gray-white [&amp;:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)] flex flex-col w-full dark:border-gray-white flex-grow relative border border-token-gray-white text-white rounded-2xl bg-gray-medium dark:bg-gray-800 shadow-[0_0_0_2px_rgba(255,255,255,0.95)] dark:shadow-[0_0_0_2px_rgba(52,53,65,0.95)]">
                      <textarea
                        id="prompt-textarea"
                        tabIndex="0"
                        data-id="request-NEW:1-0"
                        rows="1"
                        placeholder="Prompt to Optimize..."
                        className="m-0 bg-gray-black w-full resize-none border-0 py-[10px] pr-10 focus:ring-0 focus-visible:ring-0 md:py-3.5 md:pr-12 pl-10 md:pl-[55px] text-white"
                        value={userMessage}
                        onChange={(e) => {
                          if (e.target.value.trim() !== "") {
                            setUserMessage(e.target.value);
                          } else {
                            setUserMessage("");
                          }
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                          e.target.style.maxHeight = "200px";
                          if (userMessage.trim() === "") {
                            e.target.style.height = "52px";
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.shiftKey) {
                            console.log("not submiting");
                            setUserMessage(userMessage + "\n");
                          } else if (
                            e.key === "Enter" &&
                            userMessage.trim() !== ""
                          ) {
                            optimizePrompt(userMessage);
                            setUserMessage("");
                            console.log(userMessage);
                          }
                        }}
                      ></textarea>
                      {!loading ? (
                        <div
                          className="absolute right-0 mt-3 mr-3 hover:bg-gray-light rounded-xl"
                          onClick={() => {
                            optimizePrompt(userMessage);
                          }}
                        >
                          <span className="">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              className="text-white"
                            >
                              <path
                                d="M7 11L12 6L17 11M12 18V7"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      ) : (
                        <div className="absolute right-0 mt-3 mr-3 hover:bg-gray-light rounded-xl">
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              class="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
});

export default OptimizePartial;
