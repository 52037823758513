import React from "react";
import { useEffect } from "react";
import PredictorPartial from "../partials/PredictorPartial";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

const Predictor = (props) => {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="bg-gray-medium h-full">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>GPTEZ</title>
      <meta name="description" content="Free GPT-4!" />
      <meta property="og:title" content="GPTEZ" />
      <Header />
      {/*  Page content */}
      <main className="h-full">
        <PredictorPartial />
      </main>
      <Footer />
    </div>
  );
};

export default Predictor;
