import React from "react";
import Wave from "react-wavify";

const Footer = () => {
  return (
    <footer>
      <div className="bg-gray-black pt-12">
        {/* </div> */}

        {/* Bottom area */}
        <div className="flex flex-col border-gray-200">
          {/* Copyrights note */}
          <div className="flex md:flex-row sm:flex-col items-center justify-center w-full py-8">
            <p className="text-white font-medium">GPTEZ.com ©2024 </p>
            <div className="pl-4 text-white font-medium">
              All Rights Reserved{" "}
            </div>
          </div>
          <div className="relative w-full h-64">
            {" "}
            {/* Container with Tailwind classes */}
            <Wave
              fill="#7AF3E1"
              className="absolute top-0 left-0 w-full h-full"
              options={{
                height: 1,
                amplitude: 20,
                speed: 0.15,
                points: 3,
              }}
            ></Wave>
            <Wave
              fill="#f79902"
              className="absolute top-0 left-0 w-full h-full"
              options={{
                height: 50,
                amplitude: 20,
                speed: 0.15,
                points: 3,
              }}
            ></Wave>
            <Wave
              fill="#FF51A0"
              className="absolute top-16 left-0 w-full h-full" // Adjust the top positioning to make this wave higher
              options={{
                height: 100,
                amplitude: 50,
                speed: 0.15,
                points: 3,
              }}
            ></Wave>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
