import React, { useEffect, useState, useRef } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

function GalleryPartial() {
  const [imageSrcs, setImageSrcs] = useState([
    "https://storage.googleapis.com/quasi-a39a6.appspot.com/Seattle_skyline.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.30%20-%20Illustration%20of%20a%20slightly%20solarpunk-style%20Pacific%20Northwest%20tourism%20poster%2C%20titled%20'The%20Emerald%20City.'%20The%20scene%20merges%20a%20nostalgic%20view%20of%20Seattle%20w.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.49%20-%20Illustration%20of%20a%20psychedelic%20poster%20with%20a%20vintage%201960s%20flair%2C%20promoting%20a%20Euchre%20tournament.%20The%20poster%20features%20an%20explosion%20of%20abstract%20shapes%20an.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.23%20-%20Photo%20of%20a%20vintage%20Pacific%20Northwest%20tourism%20poster%2C%20featuring%20a%20panoramic%20view%20of%20Mount%20Rainier%20at%20sunset%2C%20with%20the%20sky%20painted%20in%20deep%20hues%20of%20orang.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.07.34%20-%20Photo%20of%20a%20vibrant%20cyberpunk-inspired%20college%20apartment.%20Neon%20lights%20illuminate%20the%20room%20in%20shades%20of%20blue%20and%20pink.%20Retro%20tech%20items%20like%20vintage%20com.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.07.40%20-%20Illustration%20of%20a%20college%20apartment%20with%20a%20retro%20cyberpunk%20theme.%20The%20room%20is%20bathed%20in%20a%20soft%20glow%20from%20neon%20lights%20in%20green%20and%20purple.%20On%20a%20shelf%2C%20.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.55.52%20-%20A%20vectorized%2C%20psychedelic%20art%20piece%20with%20colorful%20goop%20occupying%20only%20the%20bottom%20third%20of%20the%20image.%20The%20goop%20should%20be%20designed%20in%20clean%2C%20sharp%20vecto.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.56.11%20-%20A%20vintage%201980s%20computer%20setup%2C%20featuring%20a%20bulky%20CRT%20monitor%20with%20a%20prominent%20bezel%2C%20a%20large%20keyboard%20with%20high-profile%2C%20clicky%20keys%2C%20and%20a%20boxy%2C%20bei.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.56.20%20-%20Craft%20an%20even%20more%20asymmetrical%20and%20natural-looking%20abstract%20pattern%20on%20a%20black%20background.%20Use%20a%20minimalist%20approach%20with%20splashes%20of%20psychedelic%20col.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.57.16%20-%20Illustrate%20an%20expansive%20solar%20punk%20pop%20art%20interpretation%20of%20Seattle%2C%20showcasing%20a%20utopian%20vision%20of%20the%20city.%20The%20scene%20should%20be%20bursting%20with%20rich%2C.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.57.22%20-%20Illustrate%20an%20expansive%20solar%20punk%20pop%20art%20interpretation%20of%20Seattle%2C%20showcasing%20a%20utopian%20vision%20of%20the%20city.%20The%20scene%20should%20be%20bursting%20with%20rich%2C.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.56.24%20-%20Create%20a%20simple%20abstract%20pattern%20that%20concentrates%20in%20the%20center%20of%20a%20black%20background.%20The%20design%20should%20utilize%20a%20small%20burst%20of%20psychedelic%20colors%2C.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.57.28%20-%20Craft%20a%20detailed%20Seattle%20cityscape%20in%20a%20solar%20punk%20pop%20art%20style%20with%20an%20even%20stronger%20emphasis%20on%20pop%20art%20characteristics.%20The%20scene%20should%20feature%20t.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.57.42%20-%20A%20person%20with%20an%20anonymous%20look%20in%20dark%20casual%20clothing%2C%20wearing%20a%20hoodie%20and%20a%20bandana%20covering%20the%20lower%20half%20of%20their%20face%2C%20is%20spray-painting%20the%20s.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.57.51%20-%20A%20textured%20urban%20brick%20wall%20partially%20covered%20with%20lush%20green%20ivy.%20In%20the%20center%20of%20the%20wall%2C%20the%20graffiti%20'GPTEZ!'%20is%20clearly%20legible%2C%20written%20in%20lar.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.58.06%20-%20Design%20a%20vibrant%20solar%20punk-themed%20sticker%20with%20the%20bold%2C%20futuristic%20text%20'GPTEZ!'%20at%20the%20center.%20The%20background%20should%20be%20a%20lush%20greenery%20with%20advanc.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.58.16%20-%20Design%20an%20image%20that%20resembles%20an%20old%20green%20phosphor%20command%20line%20interface%20from%20the%20early%20computing%20era.%20The%20word%20'GPTEZ'%20should%20be%20prominently%20displ.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.58.23%20-%20Create%20a%20psychedelic%20sticker%20design%20featuring%20the%20word%20'GPTEZ!'%20in%20a%20bubbly%20and%20bold%20font.%20The%20background%20should%20be%20a%20vibrant%2C%20swirling%20pattern%20of%20col.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.58.50%20-%20Design%20a%20sticker%20that%20captures%20the%20essence%20of%20the%201960s%20with%20a%20whimsical%20twist%2C%20featuring%20a%20humanoid%20robot%20with%20a%20retro%20design.%20The%20robot%20has%20a%20metall.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.58.55%20-%20Design%20a%20vibrant%201960s-inspired%20sticker%20featuring%20a%20humanoid%20robot%20that%20combines%20the%20retro-futuristic%20charm%20of%20the%20era%20with%20a%20touch%20of%20modern%20wit.%20The.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.59.28%20-%20Depict%20a%20post-apocalyptic%20Seattle%20with%20overgrown%20vegetation%20on%20the%20iconic%20Space%20Needle%2C%20the%20skyline%20barely%20visible%20through%20a%20thick%2C%20foggy%20haze.%20The%20st.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.59.42%20-%20Create%20an%20image%20in%20a%20pop%20art%20style%2C%20reminiscent%20of%20the%20Pacific%20Northwest%20environment.%20The%20scene%20includes%20a%20background%20of%20towering%20evergreen%20trees%20unde.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.59.54%20-%20Create%20a%20vibrant%20and%20psychedelic%20sticker%20design%20featuring%20a%20human%20college%20male%20with%20an%20ambiguous%20descent%20and%20casual%20attire%2C%20standing%20next%20to%20a%20personi.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.00.20%20-%20Create%20a%20highly%20detailed%20and%20more%20vivid%20psychedelic%20sticker%20design%2C%20featuring%20a%20human%20college%20male%20with%20an%20ambiguous%20descent%20and%20trendy%20attire%2C%20next%20t.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.00.24%20-%20Create%20a%20highly%20detailed%20and%20more%20vivid%20psychedelic%20sticker%20design%2C%20featuring%20a%20human%20college%20male%20with%20an%20ambiguous%20descent%20and%20trendy%20attire%2C%20next%20t.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.01.25%20-%20Create%20a%20psychedelic%20sticker%20set%20that%20depicts%20a%20scene%20with%20two%20characters_%20a%20college-aged%20male%20with%20Asian%20descent%20and%20an%20AI%20represented%20by%20a%20futuristi.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.01.44%20-%20Create%20a%20psychedelic%20promotional%20poster%20for%20artificial%20intelligence.%20The%20background%20is%20a%20vibrant%2C%20swirling%20mix%20of%20neon%20colors%20resembling%20a%20tie-dye%20pat.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.01.53%20-%20Create%20a%20psychedelic%20sticker%20in%20the%20style%20of%20the%20previously%20referenced%20image%2C%20featuring%20a%20Black%20human%20with%20vibrant%20purple%20afro%20hair%2C%20wearing%20a%20neon%20gr.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.02.02%20-%20Craft%20a%20set%20of%20psychedelic%20stickers%20where%20a%20Caucasian%20human%20with%20multi-colored%20dreadlocks%2C%20wearing%20a%20vibrant%2C%20neon%20shirt%2C%20is%20in%20conversation%20with%20an%20A.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.02.24%20-%20Create%20a%20sticker%20design%20featuring%20a%2020-year-old%20Middle-Eastern%20male%20AI%20entrepreneur%20sitting%20cross-legged%20in%20a%20neon-lit%2C%20cluttered%20bedroom%20that%20serves%20.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.02.30%20-%20Create%20a%20sticker%20design%20featuring%20a%2020-year-old%20Middle-Eastern%20male%20AI%20entrepreneur%20sitting%20cross-legged%20in%20a%20neon-lit%2C%20cluttered%20bedroom%20that%20serves%20.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.02.42%20-%20Envision%20a%2020-year-old%20South%20Asian%20male%20sitting%20cross-legged%20on%20the%20floor%20of%20his%20small%2C%20messy%20bedroom%2C%20which%20doubles%20as%20a%20startup%20hub%2C%20reflecting%20his%20.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.03.08%20-%20Illustration%20of%20a%201990s-style%20promotional%20poster%2C%20with%20a%20sleek%2C%20metallic%20Bitcoin%20logo%20emblazoned%20against%20a%20backdrop%20of%20bright%20graffiti%20art.%20The%20logo%20h.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.03.04%20-%20Photo%20of%20a%201990s%20promotional%20poster%2C%20depicting%20a%20group%20of%20teenagers%2C%20a%20Caucasian%20girl%20and%20an%20African%20American%20boy%2C%20dressed%20in%20iconic%2090s%20attire%20-%20bagg.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.03.20%20-%20Illustration%20of%20a%201980s%20promotional%20poster%20with%20an%20arcade%20theme%2C%20featuring%20a%20diverse%20group%20of%20young%20adults%2C%20an%20Asian%20man%20and%20a%20Caucasian%20woman%2C%20wearin.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.03.27%20-%20Photo%20of%20a%20vibrant%201980s-style%20promotional%20poster%2C%20with%20a%20shiny%2C%20pixelated%20Bitcoin%20symbol%20taking%20center%20stage.%20Neon%20lights%20and%20laser%20grid%20lines%20crissc.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.03.35%20-%20Drawing%20of%20a%20vintage%201950s%20promotional%20poster%2C%20showcasing%20a%20family%20gathered%20around%20a%20gleaming%2C%20oversized%20Bitcoin%20coin%20in%20their%20mid-century%20modern%20livi.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.03.40%20-%20Illustration%20of%20a%201950s-style%20promotional%20poster%2C%20featuring%20a%20radiant%2C%20golden%20Bitcoin%20emblem%20at%20the%20center%2C%20surrounded%20by%20retro%20futuristic%20motifs%20like.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.04.02%20-%20Illustration%20of%20a%201960s%20style%20psychedelic%20travel%20poster%20for%20a%20future%20utopian%20city.%20The%20city%20is%20depicted%20with%20towering%2C%20smooth%2C%20shiny%20chrome%20skyscraper.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.04.33%20-%20Photo%20of%20a%20surreal%20album%20cover%20for%20a%20psychedelic%20AI%20robot%20band.%20The%20cover%20features%20a%20group%20of%20four%20robots%2C%20each%20with%20a%20unique%2C%20abstract%20design%2C%20playin.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.05.03%20-%20Photo%20of%20a%20sticker%20with%20an%20illustration%20of%20a%20robot%20with%20a%20large%2C%20round%20head%20and%20glowing%20digital%20eyes.%20Its%20metallic%20body%20is%20adorned%20with%20psychedelic%20pa.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.01%20-%20Photo%20of%20a%201960s%20style%20groovy%20Auburn%2C%20Alabama%20tourism%20poster.%20It%20features%20a%20vibrant%20color%20palette%20with%20hot%20pinks%2C%20electric%20blues%2C%20and%20neon%20yellows.%20Th.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.05.53%20-%20Illustration%20of%20a%20nostalgic%20Auburn%2C%20Alabama%20tourism%20poster%20in%20a%20mid-20th-century%20American%20art%20style.%20It%20showcases%20the%20Auburn%20University%20football%20stadi.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.05.21%20-%20Illustration%20of%20a%20comic%20book%20style%20scene%20depicting%20four%20college-aged%20male%20friends%20playing%20euchre.%20One%20with%20brown%20hair%20saying%20_Ight_%2C%20another%20is%20an%20Ind.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.55.40%20-%20Abstract%20colorful%20psychedelic%20art%20featuring%20simple%20icons%2C%20set%20against%20a%20pitch-black%20background.%20The%20composition%20includes%20a%20vibrant%20spectrum%20of%20colors%2C.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.30%20-%20Illustration%20of%20a%20slightly%20solarpunk-style%20Pacific%20Northwest%20tourism%20poster%2C%20titled%20'The%20Emerald%20City.'%20The%20scene%20merges%20a%20nostalgic%20view%20of%20Seattle%20w.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.49%20-%20Illustration%20of%20a%20psychedelic%20poster%20with%20a%20vintage%201960s%20flair%2C%20promoting%20a%20Euchre%20tournament.%20The%20poster%20features%20an%20explosion%20of%20abstract%20shapes%20an.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.23%20-%20Photo%20of%20a%20vintage%20Pacific%20Northwest%20tourism%20poster%2C%20featuring%20a%20panoramic%20view%20of%20Mount%20Rainier%20at%20sunset%2C%20with%20the%20sky%20painted%20in%20deep%20hues%20of%20orang.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.07.34%20-%20Photo%20of%20a%20vibrant%20cyberpunk-inspired%20college%20apartment.%20Neon%20lights%20illuminate%20the%20room%20in%20shades%20of%20blue%20and%20pink.%20Retro%20tech%20items%20like%20vintage%20com.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.07.40%20-%20Illustration%20of%20a%20college%20apartment%20with%20a%20retro%20cyberpunk%20theme.%20The%20room%20is%20bathed%20in%20a%20soft%20glow%20from%20neon%20lights%20in%20green%20and%20purple.%20On%20a%20shelf%2C%20.png",
  ]);

  const [shuffledSrcs, setShuffledSrcs] = useState([
    "https://storage.googleapis.com/quasi-a39a6.appspot.com/Seattle_skyline.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.30%20-%20Illustration%20of%20a%20slightly%20solarpunk-style%20Pacific%20Northwest%20tourism%20poster%2C%20titled%20'The%20Emerald%20City.'%20The%20scene%20merges%20a%20nostalgic%20view%20of%20Seattle%20w.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.49%20-%20Illustration%20of%20a%20psychedelic%20poster%20with%20a%20vintage%201960s%20flair%2C%20promoting%20a%20Euchre%20tournament.%20The%20poster%20features%20an%20explosion%20of%20abstract%20shapes%20an.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.06.23%20-%20Photo%20of%20a%20vintage%20Pacific%20Northwest%20tourism%20poster%2C%20featuring%20a%20panoramic%20view%20of%20Mount%20Rainier%20at%20sunset%2C%20with%20the%20sky%20painted%20in%20deep%20hues%20of%20orang.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.07.34%20-%20Photo%20of%20a%20vibrant%20cyberpunk-inspired%20college%20apartment.%20Neon%20lights%20illuminate%20the%20room%20in%20shades%20of%20blue%20and%20pink.%20Retro%20tech%20items%20like%20vintage%20com.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2018.07.40%20-%20Illustration%20of%20a%20college%20apartment%20with%20a%20retro%20cyberpunk%20theme.%20The%20room%20is%20bathed%20in%20a%20soft%20glow%20from%20neon%20lights%20in%20green%20and%20purple.%20On%20a%20shelf%2C%20.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.55.52%20-%20A%20vectorized%2C%20psychedelic%20art%20piece%20with%20colorful%20goop%20occupying%20only%20the%20bottom%20third%20of%20the%20image.%20The%20goop%20should%20be%20designed%20in%20clean%2C%20sharp%20vecto.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.56.11%20-%20A%20vintage%201980s%20computer%20setup%2C%20featuring%20a%20bulky%20CRT%20monitor%20with%20a%20prominent%20bezel%2C%20a%20large%20keyboard%20with%20high-profile%2C%20clicky%20keys%2C%20and%20a%20boxy%2C%20bei.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.56.20%20-%20Craft%20an%20even%20more%20asymmetrical%20and%20natural-looking%20abstract%20pattern%20on%20a%20black%20background.%20Use%20a%20minimalist%20approach%20with%20splashes%20of%20psychedelic%20col.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.57.16%20-%20Illustrate%20an%20expansive%20solar%20punk%20pop%20art%20interpretation%20of%20Seattle%2C%20showcasing%20a%20utopian%20vision%20of%20the%20city.%20The%20scene%20should%20be%20bursting%20with%20rich%2C.png",
    "https://storage.googleapis.com/quasi_art/DALL%C2%B7E%202023-11-13%2017.57.22%20-%20Illustrate%20an%20expansive%20solar%20punk%20pop%20art%20interpretation%20of%20Seattle%2C%20showcasing%20a%20utopian%20vision%20of%20the%20city.%20The%20scene%20should%20be%20bursting%20with%20rich%2C.png",
  ]);

  const gridRef = useRef();

  const handleShuffleClick = () => {
    const shuffled = imageSrcs.sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, imageSrcs.length);
    setShuffledSrcs(selected);
    // take user to top of page
    window.scrollTo(0, 0);
    if (gridRef.current) {
      gridRef.current.updateLayout();
    }
  };

  useEffect(() => {
    // take a random subset of 10 images
    const shuffled = imageSrcs.sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, imageSrcs.length);
    setShuffledSrcs(selected);
    if (gridRef.current) {
      gridRef.current.updateLayout();
    }
  }, []);

  return (
    <section className="flex flex-col bg-gray-black w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#FF9812"
          fill-opacity="1"
          d="M0,128L48,128C96,128,192,128,288,112C384,96,480,64,576,53.3C672,43,768,53,864,74.7C960,96,1056,128,1152,165.3C1248,203,1344,245,1392,266.7L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <div className="md:px-24 sm:px-6 mb-16">
        <div className="flex justify-start">
          <div className="flex flex-col">
            <div className="lg:text-6xl sm:text-4xl font-bold text-white">
              Gallery!
            </div>
            <div className="text-white p-8">Some things I made with ai</div>
          </div>
        </div>
        <div className="py-16 h-full">
          <div className="flex flex-col">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry>
                {shuffledSrcs.map((src, index) => (
                  <div className="flex flex-col">
                    <img
                      src={src}
                      alt=""
                      className="rounded-lg shadow-lg hover:shadow-xl"
                    />
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
        <div className="flex justify-center">
          <div
            className="bg-orange-gradient1 hover:bg-orange-gradient2 p-4 rounded-xl flex flex-row"
            onClick={() => handleShuffleClick()}
          >
            <span className="text-white pr-4 font-bold">Refresh</span>
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.201172 12.6328C0.201172 17.5059 4.12695 21.4414 9 21.4414C13.8633 21.4414 17.7891 17.5059 17.7891 12.6328C17.7891 11.9785 17.3301 11.5195 16.6855 11.5195C16.0508 11.5195 15.6211 11.9785 15.6211 12.6328C15.6211 16.3047 12.6719 19.2637 9 19.2637C5.32812 19.2637 2.37891 16.3047 2.37891 12.6328C2.37891 8.91211 5.2793 5.98242 8.94141 5.98242C9.50781 5.98242 10.0254 6.02148 10.4746 6.10938L8.01367 8.55078C7.81836 8.74609 7.71094 8.98047 7.71094 9.26367C7.71094 9.86914 8.16992 10.3281 8.76562 10.3281C9.06836 10.3281 9.32227 10.2305 9.50781 10.0352L13.5117 6.00195C13.7363 5.76758 13.8438 5.50391 13.8438 5.20117C13.8438 4.9082 13.7266 4.625 13.5117 4.41016L9.50781 0.347656C9.3125 0.132812 9.06836 0.0253906 8.76562 0.0253906C8.16992 0.0253906 7.71094 0.513672 7.71094 1.10938C7.71094 1.40234 7.81836 1.63672 8.00391 1.8418L10.1816 3.99023C9.81055 3.91211 9.38086 3.87305 8.94141 3.87305C4.07812 3.87305 0.201172 7.75977 0.201172 12.6328Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GalleryPartial;
