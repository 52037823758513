import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Transition from "../utils/Transition.js";

const Header = (props) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);

  const mobileNav = useRef(null);
  const location = useLocation(); // Get the current route location

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const history = useNavigate();

  // Function to determine if a link is active based on the current route
  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header className="w-full z-30 text-md transition duration-300 ease-in-out bg-gray-black">
      <div className="text-md xl:px-12 lg:px-0 sm:px-2 lg:ml-3 font-medium mx-auto w-full">
        <div className="text-md flex items-center justify-between h-16 md:h-20 outline-none w-full">
          <Link to="/" className="block mr-8" aria-label="Cruip">
            <img
              className="mx-auto outline-none lg:block xl:block"
              src={
                "https://storage.googleapis.com/quasi-a39a6.appspot.com/White%20Nabla.png"
              }
              width="50"
              height="50"
              alt="Hero"
            />
          </Link>
          <nav className="text-md lg:flex lg:flex-grow">
            <div className="hidden md:flex md:flex-grow">
              <ul className="flex flex-grow justify-end items-center text-sm pr-6">
                <span className="flex flex-row text-md">
                  <li>
                    <Link
                      to="/demos"
                      style={{
                        borderWidth: "0px",
                      }}
                      className={isLinkActive("/demos") ? "active-link" : ""}
                    >
                      <div className="px-4">
                        <span
                          className={`font-bold text-xl bg-clip-text text-opacity ${
                            isLinkActive("/demos")
                              ? "bg-clip-text text-transparent bg-gradient-to-r from-purple-gradient1 via-orange-gradient1 to-blue-gradient1"
                              : "text-white"
                          } hover:underline`}
                        >
                          Demos
                        </span>
                        <path
                          d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                          fillRule="nonzero"
                        />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blog"
                      style={{
                        borderWidth: "0px",
                      }}
                      className={isLinkActive("/blog") ? "active-link" : ""}
                    >
                      <div className="px-4">
                        <span
                          className={`font-bold text-xl bg-clip-text text-opacity ${
                            isLinkActive("/blog")
                              ? "bg-clip-text text-transparent bg-gradient-to-r from-purple-gradient1 via-orange-gradient1 to-blue-gradient1"
                              : "text-white"
                          } hover:underline`}
                        >
                          Blog
                        </span>
                        <path
                          d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                          fillRule="nonzero"
                        />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/gallery"
                      style={{
                        borderWidth: "0px",
                      }}
                      className={isLinkActive("/gallery") ? "active-link" : ""}
                    >
                      <div className="px-4">
                        <span
                          className={`font-bold text-xl bg-clip-text text-opacity ${
                            isLinkActive("/gallery")
                              ? "bg-clip-text text-transparent bg-gradient-to-r from-purple-gradient1 via-orange-gradient1 to-blue-gradient1"
                              : "text-white"
                          } hover:underline`}
                        >
                          Gallery
                        </span>
                        <path
                          d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                          fillRule="nonzero"
                        />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      style={{
                        borderWidth: "0px",
                      }}
                      className={isLinkActive("/about") ? "active-link" : ""}
                    >
                      <div className="px-4">
                        <span
                          className={`font-bold text-xl bg-clip-text text-opacity ${
                            isLinkActive("/about")
                              ? "bg-clip-text text-transparent bg-gradient-to-r from-purple-gradient1 via-orange-gradient1 to-blue-gradient1"
                              : "text-white"
                          } hover:underline`}
                        >
                          About
                        </span>
                        <path
                          d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                          fillRule="nonzero"
                        />
                      </div>
                    </Link>
                  </li>
                </span>
              </ul>
            </div>
          </nav>

          <div className="flex md:hidden">
            <button
              className={`hamburger ${
                mobileNavOpen && "active"
              } focus:outline-none`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </button>

            <div ref={mobileNav} className="pt-16">
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                className="absolute h-screen pb-16 z-40 left-0 w-full overscroll-contain bg-gray-black transition-all duration-300 ease-in-out"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
              >
                <ul className="px-5 py-2">
                  <li>
                    <Link
                      to="/blog"
                      className={`flex justify-center bg-clip-text text-opacity font-bold hover:underline mx-3 lg:mx-5 py-2 items-center transition duration-150 ease-in-out outline-none text-md ${
                        isLinkActive("/blog")
                          ? "bg-clip-text text-transparent bg-gradient-to-r from-purple-gradient1 via-orange-gradient1 to-blue-gradient1"
                          : "text-white"
                      }`}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/gallery"
                      className={`flex justify-center bg-clip-text text-opacity font-bold hover:underline mx-3 lg:mx-5 py-2 items-center transition duration-150 ease-in-out outline-none text-md ${
                        isLinkActive("/gallery")
                          ? "bg-clip-text text-transparent bg-gradient-to-r from-purple-gradient1 via-orange-gradient1 to-blue-gradient1"
                          : "text-white"
                      }`}
                    >
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className={`flex justify-center bg-clip-text text-opacity font-bold hover:underline mx-3 lg:mx-5 py-2 items-center transition duration-150 ease-in-out outline-none text-md ${
                        isLinkActive("/about")
                          ? "bg-clip-text text-transparent bg-gradient-to-r from-purple-gradient1 via-orange-gradient1 to-blue-gradient1"
                          : "text-white"
                      }`}
                    >
                      About
                    </Link>
                  </li>
                </ul>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
